import session from '../util/session'
import i18n, {languages} from '../util/i18n'
import {inject} from 'vue'
import OrganisationType from '../types/organisationType'

const $t = () => {
    const user = session.getUser()
    return i18n(user?.language ?? languages.NL)
}

const roles = {
    SYSTEM_ADMIN: 'SYSTEM_ADMIN',
    OVERVIEWER: 'OVERVIEWER',
    ORGANISATION_ADMIN: 'ORGANISATION_ADMIN',
    USER: 'USER',
    ANONYMOUS: 'ANONYMOUS',
    DRIVER: 'DRIVER',
    DRIVER_BOSSCHAERT: 'DRIVER_BOSSCHAERT',
    SUPPORT: 'SUPPORT',
    ALL: () => {
        return [roles.SYSTEM_ADMIN, roles.OVERVIEWER, roles.SUPPORT, roles.ORGANISATION_ADMIN, roles.USER, roles.DRIVER, roles.DRIVER_BOSSCHAERT]
    },
    getRoleText: (roles) => {
        let r = []
        if (roles.indexOf("SYSTEM_ADMIN") > -1) {
            r.push($t()('users.roles.system_admin'))
        }
        if (roles.indexOf("ORGANISATION_ADMIN") > -1) {
            r.push($t()('users.roles.organisation_admin'))
        }
        if (roles.indexOf("OVERVIEWER") > -1) {
            r.push($t()('users.roles.overviewer'))
        }
        if (roles.indexOf("SUPPORT") > -1) {
            r.push($t()('users.roles.support'))
        }
        if (roles.indexOf("USER") > -1) {
            r.push($t()('users.roles.user'))
        }
        if (roles.indexOf("DRIVER") > -1) {
            r.push($t()('users.roles.driver'))
        }
        if (roles.indexOf("DRIVER_BOSSCHAERT") > -1) {
            r.push($t()('users.roles.driver*'))
        }
        if (r.length === 0) {
            r.push($t()('users.roles.anonymous'))
        }
        return r.join(', ')
    },
    hasOneOfRoles: (r, includeSysAdmin = true) => {
        if (!Array.isArray(r)) {
            r = [r]
        }

        const allowed = includeSysAdmin ? [roles.SYSTEM_ADMIN] : []
        r.forEach((role) => {
            allowed.push(role)
        })
        const currentOrg = session.getCurrentOrganisation()
        if (currentOrg && currentOrg.roles) {
            for (let i = 0; i < currentOrg.roles.length; i++) {
                const role = currentOrg.roles[i]
                if (allowed.indexOf(role) > -1) {
                    return true
                }
            }
        }
        return false
    },
    hasOnlyRole: (r) => {
        const currentOrg = session.getCurrentOrganisation()
        return currentOrg && currentOrg.roles && currentOrg.roles.length === 1 && currentOrg.roles[0] === r
    },
    getPossibleRoles: () => {
      const flag = inject('$flag')
      const $orgType = inject('$orgType')
      let roleOptions
      if ($orgType(OrganisationType.OVERVIEWER)) {
        roleOptions = [
          {
            label: $t()('users.roles.organisation_admin'),
            value: roles.ORGANISATION_ADMIN,
            title: $t()('users.roles.help.organisation_admin')
          },
          {
            label: $t()('users.roles.overviewer'),
            value: roles.OVERVIEWER,
            title: $t()('users.roles.help.overviewer')
          },
        ]
      } else if ($orgType(OrganisationType.TRANSPORTER)) {
        roleOptions = [
          {
            label: $t()('users.roles.organisation_admin'),
            value: roles.ORGANISATION_ADMIN,
            title: $t()('users.roles.help.organisation_admin')
          },
          {
            label: $t()('users.roles.driver'),
            value: roles.DRIVER,
            title: $t()('users.roles.help.driver')
          },
        ]
      } else {
        roleOptions = [
          {
            label: $t()('users.roles.organisation_admin'),
            value: roles.ORGANISATION_ADMIN,
            title: $t()('users.roles.help.organisation_admin')
          },
          {
            label: $t()('users.roles.user'),
            value: roles.USER,
            title: $t()('users.roles.help.user')
          },
          {
            label: $t()('users.roles.driver'),
            value: flag('BOSSCHAERT') ? roles.DRIVER_BOSSCHAERT : roles.DRIVER,
            title: $t()('users.roles.help.driver')
          },
        ]
      }

      // if session is a sys-admin, I can add other sys-admins
      const me = session.getUser()
      const org = session.getOrganisation()
      let meIndex = 0
      me.organisationRoles.forEach((o, index) => {
        if (o.organisation.id === org.id) {
          meIndex = index
        }
      })
      if (me.organisationRoles[meIndex].roles.indexOf(roles.SYSTEM_ADMIN) > -1) {
        roleOptions.unshift({
          label: $t()('users.roles.system_admin'),
          value: roles.SYSTEM_ADMIN,
          title: $t()('users.roles.help.system_admin')
        })
      }
      return roleOptions
    },
}

export default roles
