<template :key="id">
  <layout v-if="driverView">
    <template #header>
      <ui-header :title="$t('grondbank.view.title', {id})" back-route="/grondbank/list">
        <template #actions>
          <template v-if="!loading">
            <div class="btn-group">
              <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="actionsDropdown"
                      data-bs-toggle="dropdown">
                {{ $t('general.actions') }}
              </button>
              <ul data-cy="driver-actions" class="dropdown-menu">
                <li id="fullViewBtn">
                  <button class="dropdown-item" @click="goToFullView()">
                    {{ $t('waste_identification.view.show_complete_form') }}
                  </button>
                </li>
                <li id="changeLogBtn">
                  <button class="dropdown-item" @click="showChangeLog()">
                    {{ $t('forms.view.view_change_log') }}
                  </button>
                </li>
                <li v-if="$flag('ATTACHMENTS')">
                  <button class="dropdown-item" @click="showAttachments()">
                    {{ $t('forms.view.manage_attachments') }}
                  </button>
                </li>
              </ul>
            </div>
            <button
                v-if="rideInProgress"
                type="button" class="btn btn-outline-secondary"
                @click="formViewDriver.showEditRide(form.soilRides.length-1)"><i class="bi-pen" />&nbsp;{{ $t('forms.view.enter_volume') }}
            </button>
            <button
                v-if="!rideInProgress"
                type="button" class="btn btn-outline-primary"
                @click="showStart()"><i class="bi-check" />&nbsp;{{ $t('forms.view.start_ride') }}
            </button>
            <button
                v-if="rideInProgress"
                type="button" class="btn btn-outline-primary"
                @click="showStop()"><i class="bi-check" />&nbsp;{{ $t('forms.view.stop_ride') }}
            </button>
          </template>
        </template>
      </ui-header>
    </template>
    <ui-breadcrumbs :breadcrumbs="breadcrumbs" />
    <ui-content class="pb-1">
      <spinner v-if="loading" />
      <template v-if="!loading">
        <grondbank-form-view-driver ref="formViewDriver" :form="form" :key="form.lastUpdate" @signed="loadForm()" @edited="loadForm()" />
      </template>
    </ui-content>
  </layout>
  <grondbank-view-user :id="id" :show-driver-view="true" @show-driver-view="driverView=true" :key="form.lastUpdate" v-else />
  <form-attachments-modal v-if="!loading" ref="attachmentsModal" :id="form.id" :attachments="form.attachments" :callback="saveAttachments" />
  <form-change-log-modal ref="changeLogModal" :form="form" />
  <start-ride-modal :callback="startRide" ref="startRideModal" />
  <stop-ride-modal :callback="stopRide" ref="stopRideModal" />
</template>

<script>

import Layout from '@/components/layout'
import UiHeader from '@/components/ui-header'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiContent from '@/components/ui-content'
import backend from '@/util/backend'
import {computed, ref, watch} from 'vue'
import Spinner from '@/components/spinner'
import {useRouter} from 'vue-router'
import {DateFormatter} from '@/services/dateFormatter'
import FormState from '@/types/formState'
import FormAttachmentsModal from '@/views/forms/shared/Attachments-Modal'
import OrganisationType from '@/types/organisationType'
import DocumentType from '@/types/documentType'
import FormChangeLogModal from '@/views/forms/shared/Change-Log-Modal.vue'
import GrondbankViewUser from '@/views/forms/grondbank/View-User.vue'
import GrondbankFormViewDriver from '@/partials/grondbank/FormViewDriver.vue'
import GrondbankModel from '@/models/GrondbankModel'
import Notifier from '@/util/notifier'
import StartRideModal from '@/views/forms/grondbank/Start-Ride-Modal.vue'
import StopRideModal from '@/views/forms/grondbank/Stop-Ride-Modal.vue'

export default {
  name: 'GrondbankViewDriver',
  computed: {
    DocumentType() {
      return DocumentType
    },
    OrganisationType() {
      return OrganisationType
    }
  },
  components: {
    StopRideModal,
    StartRideModal,
    GrondbankFormViewDriver,
    GrondbankViewUser,
    FormChangeLogModal,
    FormAttachmentsModal,
    Spinner,
    UiContent,
    UiBreadcrumbs,
    UiHeader,
    Layout
  },
  props: {
    id: {
      type: String,
      required: true,
    }
  },
  setup: (props) => {
    const notifier = Notifier()
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.GRONDBANK, BREADCRUMBS.VIEW]
    const loading = ref(true)

    const form = ref({})

    const loadForm = () => {
      backend.get(`api/documents/${props.id}`).then((r) => {
        if (r.data.type !== DocumentType.GRONDBANK) {
          router.push(`/grondbank/list`)
        }
        form.value = r.data
        loading.value = false
      })
    }
    loadForm()

    watch(() => props.id, () => {
      loadForm()
    })

    const router = useRouter()
    const edit = () => {
      router.push(`/grondbank/edit/${props.id}`)
    }

    const date = computed(() => {
      if (!loading.value) {
        return DateFormatter.formatDate(form.value.transportDate)
      }
      return ''
    })

    const updateDate = computed(() => {
      if (!loading.value) {
        return DateFormatter.formatDateTime(form.value.lastUpdate)
      }
      return ''
    })

    const attachmentsModal = ref('attachmentsModal')
    const showAttachments = () => {
      attachmentsModal.value.modal.open()
    }
    const saveAttachments = async () => {
      attachmentsModal.value.modal.close()
      loadForm()
    }

    const changeLogModal = ref('changeLogModal')
    const showChangeLog = () => {
      changeLogModal.value.modal.open()
    }

    const driverView = ref(true)
    const goToFullView = () => {
      driverView.value = false
    }

    const rideInProgress = computed(() => {
      let res = false
      form.value.soilRides.forEach((ride) => {
        if (ride.startOfTransport && !ride.endOfTransport) {
          res = true
        }
      })
      return res
    })

    const startRideModal = ref('startRideModal')
    const showStart = () => {
      startRideModal.value.ride = JSON.parse(JSON.stringify(GrondbankModel.soilRide))
      startRideModal.value.ride.startOfTransport = new Date()
      startRideModal.value.modal.open()
    }
    const startRide = async (ride) => {
      const newRide = Object.assign(JSON.parse(JSON.stringify(GrondbankModel.soilRide)), {
        description: ride.description,
        volume: ride.volume,
        startOfTransport: ride.startOfTransport.getHours().toString().padStart(2, '0') + ':' + ride.startOfTransport.getMinutes().toString().padStart(2, '0'),
      })

      form.value.soilRides.push(newRide)

      const url = `api/documents/${form.value.id}`
      const result = await backend.put(url, form.value)
      if (result.status === 200) {
        loadForm()
      } else {
        notifier.error('toast.save_failed')
      }
      return result
    }

    const stopRideModal = ref('stopRideModal')
    const showStop = () => {
      stopRideModal.value.ride = JSON.parse(JSON.stringify(form.value.soilRides.find((r) => {
        return r.startOfTransport && !r.endOfTransport
      })))
      stopRideModal.value.ride.endOfTransport = new Date()
      stopRideModal.value.modal.open()
    }
    const stopRide = async (ride) => {
      const selectedRide = form.value.soilRides.find((r) => {
        return r.startOfTransport && !r.endOfTransport
      })

      selectedRide.description = ride.description
      selectedRide.volume =  ride.volume
      selectedRide.endOfTransport = ride.endOfTransport.getHours().toString().padStart(2, '0') + ':' + ride.endOfTransport.getMinutes().toString().padStart(2, '0')

      const url = `api/documents/${form.value.id}`
      const result = await backend.put(url, form.value)
      if (result.status === 200) {
        loadForm()
      } else {
        notifier.error('toast.save_failed')
      }
      return result
    }

    const formViewDriver = ref('formViewDriver')

    return {
      breadcrumbs,
      loading,
      date,
      updateDate,
      edit,
      loadForm,
      form,
      FormState,
      attachmentsModal,
      showAttachments,
      saveAttachments,
      changeLogModal,
      showChangeLog,
      driverView,
      goToFullView,
      rideInProgress,
      showStart,
      startRideModal,
      startRide,
      showStop,
      stopRideModal,
      stopRide,
      formViewDriver,
    }
  }
}
</script>
