<template>
  <layout v-if="driverView">
    <template #header>
      <ui-header :title="$t('waste_identification.view.title', {id})" back-route="/waste-identification/list">
        <template #actions>
          <template v-if="!stopping">
            <div class="btn-group">
              <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="actionsDropdown"
                      data-bs-toggle="dropdown">
                {{ $t('general.actions') }}
              </button>
              <ul data-cy="driver-actions" class="dropdown-menu">
                <li id="fullViewBtn">
                  <button class="dropdown-item" @click="goToFullView()">
                    {{ $t('waste_identification.view.show_complete_form') }}
                  </button>
                </li>
                <li id="changeLogBtn">
                  <button class="dropdown-item" @click="showChangeLog()">
                    {{ $t('waste_identification.view.view_change_log') }}
                  </button>
                </li>
                <li
                  v-if="$roles([roles.DRIVER, roles.DRIVER_BOSSCHAERT]) && form.state && FormState.canControl(form.state)"
                  id="editBtn"
                >
                  <button class="dropdown-item" @click="showControl()">
                    {{ $t('waste_identification.view.control') }}
                  </button>
                </li>
                <li
                    v-if="(canStart || canArrive) && $roles([roles.USER, roles.DRIVER, roles.DRIVER_BOSSCHAERT]) && form.state && !FormState.isEndState(form.state)"
                    id="editBtn"
                >
                  <button class="dropdown-item" @click="edit()">
                    {{ $t('waste_identification.view.edit') }}
                  </button>
                </li>
                <li v-if="$roles([roles.USER, roles.DRIVER, roles.DRIVER_BOSSCHAERT]) && $flag('ATTACHMENTS')">
                  <button class="dropdown-item" @click="showAttachments()">
                    {{ $t('waste_identification.view.manage_attachments') }}
                  </button>
                </li>
              </ul>
            </div>
            <!-- If we can start/stop then show this as main button, else show edit-->
            <button
                v-if="(!canStart && !canArrive) && $roles([roles.USER, roles.DRIVER_BOSSCHAERT]) && form.state && !FormState.isEndState(form.state)"
                type="button" class="btn btn-outline-secondary"
                @click="edit()"><i class="bi-pen" />&nbsp;{{ $t('waste_identification.view.edit') }}
            </button>
            <button
              id="startBtn"
              v-if="canStart"
              type="button"
              class="btn btn-outline-primary"
              @click="start()"
              :disabled="locating"
            >
              {{ $t('waste_identification.view.start_transport') }}
            </button>
            <button
              id="arriveBtn"
              v-if="canArrive"
              type="button"
              class="btn btn-outline-primary"
              @click="stop()"
              :disabled="locating"
            >
              {{ $t('waste_identification.view.stop_transport') }}
            </button>
            <button
                id="backBtn"
                v-if="form.state === FormState.ARRIVED"
                type="button"
                class="d-flex d-sm-none btn btn-outline-primary"
                @click="router.push(`/waste-identification/list`)"
            >
              {{ $t('waste_identification.view.to_overview') }}
            </button>
          </template>
          <div class="btn-group" v-if="stopping && step === steps.control">
            <button type="button" class="btn btn-outline-secondary" @click="showEditWeightsModal()" :disabled="saving">
              <i class="bi-pen" />&nbsp;{{ $t('share_link.processor.edit_weights') }}
            </button>
            <button id="signForDeliveryBtn" type="button" class="btn btn-outline-primary" @click="goSign()" :disabled="saving || signing">
              <i class="bi-check" />&nbsp;{{ $t('signature.sign_for_delivery') }}
            </button>
          </div>
        </template>
      </ui-header>
    </template>
    <ui-breadcrumbs :breadcrumbs="breadcrumbs" />
    <ui-content class="pb-1">
      <spinner v-if="loading" />
      <template v-if="!loading">
        <template v-if="stopping">
          <template v-if="step === steps.arrive">
            <div id="arrive" class="d-flex justify-content-center">
              <h3 class="d-flex" v-if="!$isMobile">
                <span class="btn-group pe-3">
                  <button id="signProcessorBtn" type="button" class="btn btn-outline-primary" @click="signProcessor()">{{ $t('shared.sign_as_processor') }}</button>
                  <button id="signDriverBtn" type="button" class="btn btn-outline-secondary" @click="signDriver()">{{ $t('shared.sign_as_driver') }}</button>
                  <button id="refuseBtn" type="button" class="btn btn-outline-secondary" @click="showRefuse()" :disabled="refusing">{{ $t('shared.refuse') }}</button>
                </span>
              </h3>
              <h3 v-else>
                <button style="height:5rem; width:100%" id="signProcessorBtn" type="button" class="btn btn-outline-primary" @click="signProcessor()">{{ $t('shared.sign_as_processor') }}</button>
                <button style="height:5rem; width:100%" id="signDriverBtn" type="button" class="btn btn-outline-secondary" @click="signDriver()">{{ $t('shared.sign_as_driver') }}</button>
                <button style="height:5rem; width:100%" id="refuseBtn" type="button" class="btn btn-outline-secondary" @click="showRefuse()" :disabled="refusing">{{ $t('shared.refuse') }}</button>
              </h3>
            </div>
          </template>
          <template v-else-if="step === steps.control">
            <waste-identification-form-view :form="form" :key="form.id+'-'+form.lastUpdate" @signed="signed()" />
          </template>
          <template v-else-if="step === steps.processorinfo">
            <div class="container-fluid" >
              <form id="processor-form" class="row g-2 justify-content-center" >
                <ui-view-card data-cy="information" :title="$t('shared.processor_information')" :key="signerInfo.language">
                  <template #actions>
                    <button type="button" class="btn btn-sm btn-outline-secondary" @click="backToControl()">{{ $t('general.back') }}</button>
                  </template>
                  <ui-view-card-language v-model="signerInfo.language" :required="true" :own-language="true" />
                  <template v-if="signerInfo.language">
                    <ui-view-card-field data-cy="processor-first-name" :label="$t('user.labels.first_name')" v-model="signerInfo.firstName" :required="true" />
                    <ui-view-card-field data-cy="processor-last-name" :label="$t('user.labels.last_name')" v-model="signerInfo.lastName" :required="true" />
                    <ui-view-card-field data-cy="processor-organisation" :label="$t('user.labels.organisation')" v-model="signerInfo.organisation" :required="true" />
                    <br />
                    <br />
                    <div class="row g-0 justify-content-center">
                      <button id="signBtn" type="button" class="btn" :class="'btn-outline-primary'" @click="sign(true)" form="processor-form" :disabled="signing"><i class="bi-check" />&nbsp;{{$t('signature.sign_for_delivery')}}</button>
                    </div>
                  </template>
                </ui-view-card>
              </form>
            </div>
          </template>
        </template>
        <template v-else>
          <waste-identification-form-view-driver :form="form" :key="form.id+'-'+form.lastUpdate" @signed="signed()" />
        </template>
      </template>
    </ui-content>
  </layout>
  <waste-identification-view-user :show-driver-view="true" @show-driver-view="driverView=true" :id="id" v-else />
  <form-refuse-modal ref="refuseModal" :callback="refuse" />
  <form-change-log-modal ref="changeLogModal" :form="form" />
  <form-control-qr-modal ref="controlQRModal" :callback="control" :url=controlUrl />
  <form-attachments-modal v-if="!loading" ref="attachmentsModal" :id="form.id" :attachments="form.attachments" :callback="saveAttachments" />
  <shared-link-edit-weights v-if="!loading" ref="editWeightsModal" :form="form" :callback="saveWeightEdit" />
</template>

<script>

import Layout from '@/components/layout'
import UiHeader from '@/components/ui-header'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiContent from '@/components/ui-content'
import backend from '@/util/backend'
import {computed, inject, ref, watch} from 'vue'
import Spinner from '@/components/spinner'
import {useRouter} from 'vue-router'
import {DateFormatter} from '@/services/dateFormatter'
import Notifier from '@/util/notifier'
import FormState from '@/types/formState'
import OrganisationType from '@/types/organisationType'
import roles from '@/util/roles'
import geolocation from '@/services/geolocation'
import session from '@/util/session'
import ShareLinkType from '@/types/shareLinkType'
import DocumentType from '@/types/documentType'
import WasteIdentificationViewUser from '@/views/forms/waste-identification/View-User'
import WasteIdentificationFormViewDriver from '@/partials/waste-identification/FormViewDriver'
import FormChangeLogModal from '@/views/forms/shared/Change-Log-Modal'
import FormControlQrModal from '@/views/forms/shared/Control-Qr-Modal'
import SharedLinkEditWeights from '@/views/shared-links/Shared-Link-Edit-Weights'
import FormAttachmentsModal from '@/views/forms/shared/Attachments-Modal.vue'
import WasteIdentificationFormView from '@/partials/waste-identification/FormView.vue'
import UiViewCard from '@/components/ui-view-card.vue'
import UiViewCardField from '@/components/ui-view-card-field.vue'
import UiViewCardLanguage from '@/components/ui-view-card-language.vue'
import FormRefuseModal from '@/views/forms/shared/Refuse-Modal.vue'

export default {
  name: 'WasteIdentificationViewDriver',
  computed: {
    OrganisationType() {
      return OrganisationType
    }
  },
  components: {
    FormRefuseModal,
    UiViewCardLanguage,
    UiViewCardField,
    UiViewCard,
    WasteIdentificationFormView,
    FormAttachmentsModal,
    SharedLinkEditWeights,
    FormControlQrModal,
    FormChangeLogModal,
    WasteIdentificationFormViewDriver,
    WasteIdentificationViewUser,
    Spinner,
    UiContent,
    UiBreadcrumbs,
    UiHeader,
    Layout
  },
  props: {
    id: {
      type: String,
      required: true,
    }
  },
  setup: (props) => {
    const $tset = inject('$tset')
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.WASTE_IDENTIFICATION, BREADCRUMBS.VIEW]
    const notifier = Notifier()
    const loading = ref(true)

    const form = ref({})
    const loadForm = () => {
      backend.get(`api/documents/${props.id}`).then((r) => {
        if (r.data.type !== DocumentType.WASTE_IDENTIFICATION) {
          router.push(`/waste-identification/list`)
        }
        form.value = r.data
        loading.value = false
      })
    }
    loadForm()

    watch(() => props.id, () => {
      loadForm()
    })

    const router = useRouter()
    const edit = () => {
      router.push(`/waste-identification/edit/${props.id}`)
    }

    const date = computed(() => {
      if (!loading.value) {
        return DateFormatter.formatDate(form.value.transportDate)
      }
      return ''
    })

    const updateDate = computed(() => {
      if (!loading.value) {
        return DateFormatter.formatDateTime(form.value.lastUpdate)
      }
      return ''
    })

    const changeLogModal = ref('changeLogModal')
    const showChangeLog = () => {
      changeLogModal.value.modal.open()
    }

    const attachmentsModal = ref('attachmentsModal')
    const showAttachments = () => {
      attachmentsModal.value.modal.open()
    }
    const saveAttachments = async () => {
      attachmentsModal.value.modal.close()
      loadForm()
    }

    // ********************
    // start and arrive for DRIVER role

    const canStart = computed(() => {
      return roles.hasOneOfRoles(roles.DRIVER, false) && FormState.canStart(form.value.state)
    })

    const canArrive = computed(() => {
      return roles.hasOneOfRoles(roles.DRIVER, false) && FormState.canArrive(form.value.state)
    })

    watch(() => canStart.value, (v) => {
      if (v) {
        geolocation.watchPosition()
      }
    })

    watch(() => canArrive.value, (v) => {
      if (v) {
        geolocation.watchPosition()
      }
    })

    const locating = ref(false)
    geolocation.watchPosition()
    const start = () => {
      locating.value = true
      notifier.notify('toast.getting_location')
      if (!geolocation.getLocation()) {
        setTimeout(() => {
          start()
        }, 3000)
      } else {
        const user = session.getUser()
        const organisation = session.getOrganisation()
        backend.put(`api/documents/${props.id}/start`, {
          'coordinates': geolocation.getLocation(),
          'firstName': user.firstName,
          'lastName': user.lastName,
          'organisation': organisation.name,
        }).then((r) => {
          notifier.success('toast.start_location_saved')
          form.value = r.data
        }).catch(() => {
          notifier.error('toast.location_failed')
        }).finally(() => {
          locating.value = false
        })
      }
    }

    const steps = {
      arrive:        'arrive',
      control:       'control',
      processorInfo: 'processorInfo'
    }

    const stopping = ref(false)
    const step = ref(steps.arrive)
    const stop = () => {
      stopping.value = true
      step.value     = steps.arrive
    }

    const signer = ref(null)
    const signerInfo = ref({})
    const signProcessor = () => {
      signer.value = 'PROCESSOR'
      step.value = steps.control
      signerInfo.value = {
        'language': form.value.processorIdentification.language,
        'organisation': form.value.processorIdentification.name,
      }
    }
    // language gets set when signer is chosen, so we change to that person's language
    watch(() => signerInfo.value.language, (l) => {
      $tset(l)
    })

    const signDriver = () => {
      signer.value = 'DRIVER'
      step.value = steps.control
      const user = session.getUser()
      const organisation = session.getOrganisation()
      signerInfo.value = {
        'firstName': user.firstName,
        'lastName': user.lastName,
        'language': user.language,
        'organisation': organisation.name,
      }
    }

    const backToControl = () => {
      $tset(session.getLanguage())
      step.value = steps.control
    }

    const signing = ref(false)
    const goSign = () => {
      if (signer.value === 'PROCESSOR') {
        step.value = steps.processorinfo
      } else {
        sign()
      }
    }

    const sign = (checkForm = false) => {
      signing.value = true
      if (checkForm) {
        const formElement = $('#processor-form')[0]
        if (!formElement.checkValidity()) {
          // something in the form is not valid so don't allow signing
          formElement.reportValidity()
          signing.value = false
          return false
        }
      }
      performSign()
    }

    const performSign = () => {
      locating.value = true
      notifier.notify('toast.getting_location')
      if (!geolocation.getLocation()) {
        setTimeout(() => {
          performSign()
        }, 3000)
      } else {
        locating.value = false
        const signingUrl = `api/documents/${props.id}/stop`
        backend.put(signingUrl, {
          'coordinates': geolocation.getLocation(),
          'firstName': signerInfo.value.firstName,
          'lastName': signerInfo.value.lastName,
          'organisation': signerInfo.value.organisation,
        }).then((r) => {
          notifier.success('toast.form_signed')
          stopping.value = false
          form.value = r.data
          $tset(session.getLanguage())
        }).catch(() => {
          notifier.error('toast.form_sign_failed')
        }).finally(() => {
          signing.value = false
        })
      }
    }

    const controlUrl = ref('')
    const controlQRModal = ref('controlQRModal')
    const showControl = () => {
      backend.post('api/sharedlinks', {
        documentId:    props.id,
        shareLinkType: ShareLinkType.CONTROL,
        documentType:  DocumentType.WASTE_IDENTIFICATION,
      }).then((result) => {
        controlUrl.value = `${window.location.hostname === 'localhost' ? window.location.hostname+':'+8082 : window.location.hostname }/#/shared-links/${result.data.id}`
      })
      controlQRModal.value.modal.open()
    }

    const control = async (email) => {
      await backend.put(`api/documents/${props.id}/share`, {
        email: email,
      }).then((result) => {
        if (result.status === 200) {
          notifier.success('toast.sharing_successful')
        } else {
          notifier.error('toast.sharing_failed')
        }
      }).finally(() => {
        controlQRModal.value.modal.close()
      })
    }

    const refuseModal = ref('refuseModal')
    const showRefuse = () => {
      refuseModal.value.modal.open()
    }
    const refusing = ref(false)
    const refuse = (reason) => {
      refuseModal.value.modal.close()
      refusing.value = true
      stopping.value = false
      notifier.notify('toast.refusing_transport')
      backend.put(`api/documents/${props.id}/refuse2`, {reason: reason}).then((r) => {
        notifier.success('toast.transport_refused')
        form.value = r.data
      }).finally(() => {
        refusing.value = false
      })
    }

    const signed = () => {
      $tset(session.getLanguage())
      stopping.value = false
      signing.value = false
      loadForm()
    }

    const editWeightsModal = ref('editWeightsModal')
    const showEditWeightsModal = () => {
      editWeightsModal.value.modal.open()
    }

    const saving = ref(false)
    const saveWeightEdit = async () => {
      saving.value = true
      const result = await backend.put(`api/documents/${props.id}`, form.value)
      saving.value = false
      if (result.status === 200) {
        notifier.success('toast.successfully_saved')
        loadForm()
        return true
      } else {
        notifier.error('toast.save_failed')
        return false
      }
    }

    // ********************

    const driverView = ref(true)
    const goToFullView = () => {
      driverView.value = false
    }

    return {
      breadcrumbs,
      loading,
      router,
      date,
      updateDate,
      edit,
      form,
      FormState,
      showChangeLog,
      changeLogModal,
      canStart,
      canArrive,
      backToControl,
      start,
      stop,
      stopping,
      locating,
      controlQRModal,
      showControl,
      control,
      controlUrl,
      step,
      steps,
      refuseModal,
      showRefuse,
      refuse,
      refusing,
      signProcessor,
      signDriver,
      signerInfo,
      signing,
      signed,
      goSign,
      sign,
      saveWeightEdit,
      editWeightsModal,
      showEditWeightsModal,
      saving,
      attachmentsModal,
      showAttachments,
      saveAttachments,
      driverView,
      goToFullView,
    }
  }
}
</script>
