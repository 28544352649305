import { computed } from 'vue'

// https://www.vuemastery.com/blog/vue-3-data-down-events-up/
export function useModelWrapper(props, emit, name = 'modelValue') {
    return computed({
        get: () => {
            return props[name]
        },
        set: (value) => {
            emit(`update:${name}`, value)
        }
    })
}