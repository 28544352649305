<template>
  <div class="container-fluid">
    <form id="contact-form" class="row g-2 justify-content-center">
      <ui-view-card :title="$t('contracts.title')">
        <div class="row">
          <div class="col-lg-6">
            <ui-view-card-field :label="$t('contracts.start')" :required="true">
              <datepicker data-cy="wi-transport-date" v-model="itemRef.start" :enableTimePicker="false" :autoApply="true" format="dd/MM/yyyy" :required="true" />
            </ui-view-card-field>
          </div>
          <div class="col-lg-6">
            <ui-view-card-field :label="$t('contracts.stop')" :required="true">
              <datepicker data-cy="wi-transport-date" v-model="itemRef.stop" :enableTimePicker="false" :autoApply="true" format="dd/MM/yyyy" :required="true" />
            </ui-view-card-field>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <ui-view-card-number data-cy="contract-credits" :label="$t('contracts.credits')" v-model="itemRef.initialCredits" :required="true" />
          </div>
          <div class="col-lg-6">
            <ui-view-card-number data-cy="contract-cost" :label="$t('contracts.cost')" v-model="itemRef.cost" />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <ui-view-card-number data-cy="contract-overdraft" :label="$t('contracts.overdraft')" v-model="itemRef.overdraft" :readonly="true" />
          </div>
        </div>
        <ui-view-card-field :label="$t('contracts.state')" :required="true">
          <v-select data-cy="contact-type" :options="states" v-model="itemRef.contractState" :reduce="type => type.value" disabled>
            <template #search="{attributes, events}">
              <input
                class="vs__search"
                :required="!itemRef.contractState"
                v-bind="attributes"
                v-on="events"
              />
            </template>
            <template #no-options>
              {{ $t('general.no_options') }}
            </template>
          </v-select>
        </ui-view-card-field>
        <ui-view-card-field data-cy="contract-invoice-id" :label="$t('contracts.invoice_id')" v-model="itemRef.invoiceId" />
      </ui-view-card>
    </form>
  </div>
</template>

<script>

import {inject, ref} from 'vue'
import UiViewCard from '@/components/ui-view-card'
import UiViewCardField from '@/components/ui-view-card-field'
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import UiViewCardNumber from '@/components/ui-view-card-number.vue'

export default {
  name: 'ContractForm',
  components: {
    UiViewCardNumber,
    UiViewCardField,
    UiViewCard,
    Datepicker,
  },
  props: {
    item: {
      type:     Object,
      required: true,
    },
  },
  setup: (props) => {
    const $t = inject('$t')

    let itemRef = ref(props.item)

    const states = ref([
      { label: $t('contracts.status.ACTIVE'), value: 'ACTIVE' },
      { label: $t('contracts.status.FUTURE'), value: 'FUTURE' },
      { label: $t('contracts.status.FINISHED'), value: 'FINISHED' },
      { label: $t('contracts.status.IN_OVERDRAFT'), value: 'IN_OVERDRAFT' },
      { label: $t('contracts.status.LOW_CREDITS'), value: 'LOW_CREDITS' },
    ])

    return {
      itemRef,
      states,
      Datepicker,
    }
  }
}
</script>
