<template>
  <template v-if="signatureRef?.signingDate">
    {{ signatureRef.firstName + " " + signatureRef.lastName }}
    <template v-if="signatureRef.email">
      <br />
      {{ signatureRef.email }}
    </template>
    <br />
    {{ signatureRef.organisation }}
    <br />
    {{ formatDate(signatureRef.signingDate) }}
  </template>
  <template v-else-if="canSign()">
    <button id="signBtn" type="button" class="btn btn-outline-primary" @click="showSign()"><i class="bi-check" />&nbsp;{{$t('signature.sign')}}</button>
  </template>
  <template v-else>
    <h4 class="text-center">
      {{$t('signature.not_signed')}}
    </h4>
  </template>
  <form-sign-form-modal v-if="rideId === 0" ref="signModal" :callback="sign" :signature-type="signatureType" />
  <form-sign-ride-modal v-else ref="signModal" :callback="signRide" :signature-type="signatureType" />
</template>

<script>

import {ref} from 'vue'
import backend from '@/util/backend'
import Notifier from '@/util/notifier'
import session from '@/util/session'
import FormSignRideModal from '@/views/forms/shared/Sign-Ride-Modal'
import FormSignFormModal from '@/views/forms/shared/Sign-Basic-Modal'

export default {
  name: 'ui-view-card-signature-field',
  components: {
    FormSignFormModal,
    FormSignRideModal,
  },
  props: {
    signature: {
      type:    Object,
      default: () => {},
    },
    id: {
      type:    Number,
      default: -1,
    },
    signable: {
      type:    Boolean,
      default: false,
    },
    edit: {
      type:    Boolean,
      default: false
    },
    rideId: {
      type:    Number,
      default: 0,
    },
    signatureType: {
      type:    String,
      default: null,
    },
    sharelink: {
      type:    Boolean,
      default: false,
    },
    uuid: {
      type:    String,
      default: '',
    },
  },
  emits: [ 'signed' ],
  setup: (props, { emit }) => {
    const signModal = ref('signModal')
    const notifier = Notifier()
    const signatureRef = ref(props.signature)

    const showSign = () => {
      signModal.value.modal.open()
    }

    const sign = (info) => {
      const url = props.sharelink ? `api/sharedlinks/${props.uuid}/signBasic` : `api/documents/${props.id}/signBasic`
      backend.put(url, info).then(() => {
        session.setOrganisation()
        notifier.success('toast.form_signed')
        emit('signed')
      }).catch(() => {
        notifier.error('toast.form_sign_failed')
      }).finally(() => {
        signModal.value.modal.close()
      })
    }

    const signRide = (info) => {
      const url = props.sharelink ? `api/sharedlinks/${props.uuid}/rides/${props.rideId}/sign` : `api/documents/${props.id}/rides/${props.rideId}/sign`
      backend.put(url, info).then(() => {
        session.setOrganisation()
        notifier.success('toast.ride_signed')
        emit('signed')
      }).catch(() => {
        notifier.error('toast.ride_sign_failed')
      }).finally(() => {
        signModal.value.modal.close()
      })
    }

    const dateOptions = {
      year: 'numeric', month: 'numeric', day: 'numeric',
      hour: 'numeric', minute: 'numeric', second: 'numeric',
    }

    const formatDate = (date) => {
      const d = new Date(date)
      return new Intl.DateTimeFormat('nl', dateOptions).format(d)
    }

    // Can only ever sign on View page, not Edit pages
    const canSign = () => {
      return !props.edit && props.signable
    }

    return {
      signModal,
      canSign,
      showSign,
      sign,
      signRide,
      formatDate,
      signatureRef,
    }
  }
}
</script>
