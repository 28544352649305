<template>
  <ui-modal ref="modal" :callback="cb" :can-confirm="isValid">
    <template #title>
      {{ $t('grondbank.sign_ride.title')}}
    </template>
    <template #default>
      {{ $t('grondbank.sign_ride.text') }}
      <br />
      <br />
      <ui-view-card-field label="Signer" :required="true">
        <v-select :options="signatureTypes" v-model="info.signatureType" :reduce="signatureType => signatureType.value">
          <template #search="{attributes, events}">
            <input
                class="vs__search"
                :required="!info.signatureType"
                v-bind="attributes"
                v-on="events"
            />
          </template>
        </v-select>
      </ui-view-card-field>
      <form id="grondbank-offer-sign-ride-form">
        <ui-view-card-field :label="$t('forms.sign_ride_modal.first_name')" v-model="info.firstName" :required="true" />
        <ui-view-card-field :label="$t('forms.sign_ride_modal.last_name')" v-model="info.lastName" :required="true" />
        <ui-view-card-field :label="$t('forms.sign_ride_modal.organisation')" v-model="info.organisation" :required="true" />
      </form>
    </template>
  </ui-modal>
</template>

<script>

import UiModal from '@/components/ui-modal'
import {ref, watch} from 'vue'
import UiViewCardField from '@/components/ui-view-card-field'
import SignatureType from '@/types/signatureType'

export default {
  name: 'OfferSignRideModal',
  components: {
    UiViewCardField,
    UiModal
  },
  props: {
    callback: {
      type:     Function,
      required: true,
    },
    endOfTransport: {
      type:    String,
      default: '',
    },
  },
  setup: (props) => {
    const modal = ref('modal')
    const signatureTypes = SignatureType.getAll()
    const info = ref({
      signatureType: null,
    })

    watch(() => props.endOfTransport, () => {
      info.value.endOfTransport = props.endOfTransport ? {
        hours: props.endOfTransport.slice(0,2),
        minutes: props.endOfTransport.slice(3,5)
      } : ''
    })

    const cb = async () => {
      const formElement = $('#grondbank-offer-sign-ride-form')[0]
      if (!formElement.checkValidity()) {
        // something in the form is not valid so don't allow signing
        formElement.reportValidity()
        return false
      }

      let res = props.callback(info.value)
      if (res) {
        modal.value.close()
      }
    }

    const isValid = () => {
      return info.value.signatureType && info.value.firstName && info.value.lastName && info.value.organisation
    }

    return {
      modal,
      info,
      cb,
      isValid,
      signatureTypes,
    }
  }
}
</script>
