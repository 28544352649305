<template>
  <layout>
    <template #header>
      <ui-header :title="loading ? '' : $t('grondbank.view.title', {id: link.documentId})" :back-function="step === steps.arrive ? backToViewing : null">
        <template #actions>
          <template v-if="!loading && step === steps.viewing">
            <div class="btn-group">
              <button type="button" class="btn btn-outline-secondary dropdown-toggle" id="viewingActions" data-bs-toggle="dropdown" aria-expanded="false">
                {{$t('general.actions')}}
              </button>
              <ul class="dropdown-menu dropdown-menu-end text-large" aria-labelledby="viewingActions">
                <li v-if="!FormState.isEndState(form.state)">
                  <button id="editBtn" type="button" class="dropdown-item btn btn-outline-secondary" @click="edit()">{{ $t('grondbank.view.edit') }}</button>
                </li>
                <li v-if="attachmentsFlag">
                  <button id="attachmentsBtn" type="button" class="dropdown-item btn btn-outline-secondary" @click="showAttachments()">{{ $t('grondbank.view.manage_attachments') }}</button>
                </li>
              </ul>
            </div>
          </template>
          <template v-if="!loading && step === steps.editing">
            <div class="btn-group pe-3">
              <button type="button" class="btn btn-outline-secondary" @click="stopEdit()"><i class="bi-arrow-left" />&nbsp;{{ $t('general.back') }}</button>
              <button type="button" class="btn btn-outline-primary" :disabled="saving" @click="save()"><i class="bi-save" />&nbsp;{{ $t('general.save') }}</button>
            </div>
          </template>
        </template>
      </ui-header>
    </template>
    <ui-content class="pb-1">
      <spinner v-if="loading" />
      <template v-else-if="step === steps.driverinfo">
        <div class="container-fluid" >
          <form id="driver-form" class="row g-2 justify-content-center" >
            <ui-view-card data-cy="information" :title="$t('shared.driver_information')" :key="driver.language">
              <ui-view-card-language v-model="driver.language" :required="true" :own-language="true" />
              <template v-if="driver.language">
                <ui-view-card-field data-cy="driver-email" :label="$t('drivers.form.email')" v-model="driver.email" :required="true" :readonly="true" />
                <ui-view-card-field data-cy="driver-first-name" :label="$t('drivers.form.first_name')" v-model="driver.firstName" :required="true" />
                <ui-view-card-field data-cy="driver-last-name" :label="$t('drivers.form.last_name')" v-model="driver.lastName" :required="true" />
                <ui-view-card-field data-cy="driver-organisation" :label="$t('drivers.form.organisation')" v-model="driver.organisation" :required="true" />
                <br />
                <br />
                <div class="row g-0 justify-content-center">
                  <button id="saveDriverBtn" type="button" class="btn" :class="'btn-outline-primary'" @click="saveDriver()" form="driver-form" :disabled="driverSaving"><i class="bi-save" />&nbsp;{{$t('general.confirm')}}</button>
                </div>
              </template>
            </ui-view-card>
          </form>
        </div>
      </template>
      <template v-else-if="step === steps.viewing">
        <grondbank-form-view :form="form" :sharelink="true" :uuid="link.id" @ride-added="loadForm()" @signed="loadForm()" />
      </template>
      <template v-else-if="step === steps.editing">
        <grondbank-form :item="form" />
      </template>
    </ui-content>
  </layout>
  <form-attachments-modal v-if="!loading" ref="attachmentsModal" :sharelink="true" :uuid="link.id" :callback="saveAttachments" :attachments="form.attachments" />
  <form-refuse-modal ref="refuseModal" :callback="refuse" />
</template>

<script>

import UiContent from '@/components/ui-content'
import {inject, ref, watch} from 'vue'
import UiViewCard from '@/components/ui-view-card'
import UiViewCardField from '@/components/ui-view-card-field'
import Notifier from '@/util/notifier'
import backend from '@/util/backend'
import Layout from '@/components/layout'
import UiHeader from '@/components/ui-header'
import ShareLinkType from '@/types/shareLinkType'
import Spinner from '@/components/spinner'
import geolocation from '@/services/geolocation'
import UiViewCardLanguage from '@/components/ui-view-card-language'
import FormState from '@/types/formState'
import FormAttachmentsModal from '@/views/forms/shared/Attachments-Modal'
import GrondbankForm from '@/partials/grondbank/Form'
import GrondbankFormView from '@/partials/grondbank/FormView'
import GrondbankModel from '@/models/GrondbankModel'
import FormRefuseModal from '@/views/forms/shared/Refuse-Modal.vue'

export default {
  name: 'SharedLinkDriverGrondbank',
  computed: {
    FormState() {
      return FormState
    }
  },
  components: {
    FormRefuseModal,
    GrondbankFormView,
    GrondbankForm,
    FormAttachmentsModal,
    UiViewCardLanguage,
    Spinner,
    UiHeader,
    Layout,
    UiViewCardField,
    UiViewCard,
    UiContent,
  },
  props: {
    link: {
      type:     Object,
      required: true,
    }
  },
  setup: (props) => {
    const $tset = inject('$tset')
    const notifier = Notifier()

    const steps = {
      driverinfo: 'driverinfo',
      viewing:  'viewing',
      editing:  'editing',
      arrive:   'arrive',
      signing:  'signing',
    }
    const step = ref(steps.driverinfo)

    $tset(props.link.language)
    const driver = ref({
      email: props.link.email,
      firstName: props.link.firstName,
      lastName: props.link.lastName,
      organisation: props.link.organisation,
      language: props.link.language,
    })
    watch(() => driver.value.language, (l) => {
      $tset(l)
    })

    const loading = ref(true)
    const driverSaving = ref(false)
    const saveDriver = () => {
      driverSaving.value = true
      const driverForm = $('#driver-form')[0]
      if (!driverForm.checkValidity()) {
        // something in the form is not valid so don't allow signing
        driverForm.reportValidity()
        driverSaving.value = false
        return
      }
      backend.put(`/api/sharedlinks/${props.link.id}/userData`, {
        firstName: driver.value.firstName,
        lastName: driver.value.lastName,
        organisation: driver.value.organisation,
        language: driver.value.language,
      }).then(() => {
        notifier.success('toast.user_confirmed')
        step.value = steps.viewing
      }).finally(() => {
        driverSaving.value = false
      })
    }

    const form = ref({})
    const loadForm = () => {
      backend.get(`api/sharedlinks/${props.link.id}/document`).then((doc) => {
        form.value = doc.data
        loading.value = false
      })
    }
    loadForm()

    const locating = ref(false)
    geolocation.watchPosition()
    const start = () => {
      locating.value = true
      geolocation.setLanguage(driver.value.language)
      notifier.notify('toast.getting_location')
      if (!geolocation.getLocation()) {
        setTimeout(() => {
          start()
        }, 3000)
      } else {
        locating.value = false
        backend.put(`api/sharedlinks/${props.link.id}/start`, {
          'coordinates': geolocation.getLocation(),
          'firstName': driver.value.firstName,
          'lastName': driver.value.lastName,
          'organisation': driver.value.organisation,
          'shareLinkUuid': props.link.id,
        }).then(() => {
          notifier.success('toast.start_location_saved')
          loadForm()
        }).catch(() => {
          notifier.error('toast.location_failed')
        })
      }
    }

    const arrive = () => {
      step.value = steps.arrive
    }

    const edit = () => {
      step.value = steps.editing
    }

    const saving = ref(false)
    const save = async () => {
      const err = GrondbankModel.formCheck(form.value)
      if (err) {
        const formElement = $('#grondbank-form')[0]
        formElement.reportValidity()
        notifier.error(err)
        return false
      }

      saving.value = true
      const result = await backend.put(`api/sharedlinks/${props.link.id}/document/${props.link.documentId}`, form.value)
      saving.value = false
      if (result.status === 200) {
        notifier.success('toast.successfully_saved')
        loadForm()
      } else {
        notifier.error('toast.save_failed')
      }
      stopEdit()
    }

    const saveWeightEdit = async () => {
      saving.value = true
      const result = await backend.put(`api/sharedlinks/${props.link.id}/document/${props.link.documentId}`, form.value)
      saving.value = false
      if (result.status === 200) {
        notifier.success('toast.successfully_saved')
        loadForm()
        return true
      } else {
        notifier.error('toast.save_failed')
        return false
      }
    }

    const stopEdit = () => {
      loadForm()
      step.value = steps.viewing
    }

    const controlUrl = ref('')
    const controlQRModal = ref('controlQRModal')
    const control = () => {
      backend.put(`api/sharedlinks/${props.link.id}`, {
        shareLinkType: ShareLinkType.CONTROL,
      }).then((result) => {
        controlUrl.value  = `${window.location.hostname === 'localhost' ? window.location.hostname+':'+8082 : window.location.hostname }/#/shared-links/${result.data.id}`
      })
      controlQRModal.value.modal.open()
    }

    const refuseModal = ref('refuseModal')
    const showRefuse = () => {
      refuseModal.value.modal.open()
    }
    const refuse = (reason) => {
      refuseModal.value.modal.close()
      notifier.notify('toast.refusing_transport')
      backend.put(`api/documents/${form.value.id}/refuse2`, {reason: reason}).then(() => {
        notifier.success('toast.transport_refused')
        loadForm()
        step.value = steps.viewing
      })
    }

    const signerInfo = ref({})
    const signProcessor = () => {
      step.value = steps.signing
      signerInfo.value = {
        'language': form.value.processorIdentification.language,
        'organisation': form.value.processorIdentification.name,
      }
    }

    const signDriver = () => {
      step.value = steps.signing
      signerInfo.value = {
        'firstName':    driver.value.firstName,
        'lastName':     driver.value.lastName,
        'language':     driver.value.language,
        'organisation': driver.value.organisation,
      }
    }

    const signing = ref(false)
    // click button at bottom to trigger signing
    const clickSignProcessorBtn = () => {
      signing.value = true
      document.getElementById('signProcessorBtn').click()
      setTimeout(() => {
        signing.value = false
      }, 4000)
    }

    const isProcessorSet = ref(false)

    const backToViewing = () => {
      step.value = steps.viewing
    }

    const signed = () => {
      step.value = steps.viewing
      signing.value = false
      loadForm()
    }

    const editWeightsModal = ref('editWeightsModal')
    const showEditWeightsModal = () => {
      editWeightsModal.value.modal.open()
    }

    const changeLogModal = ref('changeLogModal')
    const showChangeLog = () => {
      changeLogModal.value.modal.open()
    }

    const config = inject('config')
    const attachmentsFlag = ref(config.value.getConfig('ATTACHMENTS'))
    const attachmentsModal = ref('attachmentsModal')
    const showAttachments = () => {
      attachmentsModal.value.modal.open()
    }
    const saveAttachments = async () => {
      attachmentsModal.value.modal.close()
      loadForm()
    }

    return {
      loading,
      step,
      steps,
      driver,
      saveDriver,
      driverSaving,
      form,
      edit,
      stopEdit,
      save,
      saving,
      controlQRModal,
      control,
      start,
      arrive,
      controlUrl,
      refuseModal,
      showRefuse,
      refuse,
      backToViewing,
      signProcessor,
      signDriver,
      signerInfo,
      signing,
      clickSignProcessorBtn,
      isProcessorSet,
      signed,
      saveWeightEdit,
      editWeightsModal,
      showEditWeightsModal,
      locating,
      changeLogModal,
      showChangeLog,
      attachmentsFlag,
      attachmentsModal,
      saveAttachments,
      showAttachments,
      loadForm,
    }
  }
}
</script>
