import DocumentType from '../types/documentType'
import FormCheckState from '../types/formCheckState'
import IdentificationType from '../types/identificationType'

const checkAlways = () => {
  const formElement = $('#waste-identification-form')[0]
  if (!formElement) {
    return 'toast.form_missing'
  }
  if (!formElement.checkValidity()) {
    // something in the form is not valid
    return 'toast.form_incomplete'
  }
}

const checkToSign = (form) => {
  const localOffset = new Date().getTimezoneOffset()/-60 // offset to UTC in hours
  let date = new Date(form.transportDate)
  date.setHours(date.getHours()+localOffset)
  date = date.setUTCHours(0, 0, 0, 0)
  const today = new Date().setUTCHours(0, 0, 0, 0)
  // if date is in past
  if (date < today) {
    return 'toast.date_in_past'
  }
}

const checkMatis = (form) => {
  // if no IHM then no upload needed
  if (!form.organiserIdentification.name) {
    return {state: FormCheckState.NO_UPLOAD}
  }

  // if producer shipment address (pickup location) not from flanders then no upload needed
  const address = form?.producerIdentification?.shipmentAddress?.id ? form.producerIdentification.shipmentAddress : form.producerIdentification.address
  if (address?.country !== 'BE'
      || (address?.zip < 1500)
      || (address?.zip >= 4000 && address?.zip < 8000)
  ) {
    return {state: FormCheckState.NO_UPLOAD}
  }

  const fields = []

  if (form?.producerIdentification?.identifications.filter((identification) => {
    return [IdentificationType.INDIVIDUAL, IdentificationType.COMPANY, IdentificationType.VAT, IdentificationType.BRANCH_NUMBER].indexOf(identification.identificationType) > -1
  }).length === 0) {
    fields.push('producer_identification')
  }

  if (!form?.producerIdentification?.locationType) {
    fields.push('producer_location_type')
  }

  if (form?.processorIdentification?.identifications.filter((identification) => {
    return [IdentificationType.INDIVIDUAL, IdentificationType.COMPANY, IdentificationType.VAT, IdentificationType.BRANCH_NUMBER].indexOf(identification.identificationType) > -1
  }).length === 0) {
    fields.push('processor_identification')
  }

  if (!form?.processorIdentification?.locationType) {
    fields.push('processor_location_type')
  }

  const transporterCount = form?.transporterIdentifications?.length
  let motCount = 0
  form?.transporterIdentifications.forEach((t) => {
    if (t.meansOfTransport) {
      motCount++
    }
  })
  if (!(transporterCount > 0 && transporterCount === motCount)) {
      fields.push('transporter_means_of_transport')
  }

  if (fields.length > 0) {
    return {
      state: FormCheckState.BAD_FIELDS,
      fields: fields,
    }
  }
  return {
    state: FormCheckState.OK
  }
}

const WasteIdentificationModel = {
  empty:     {
    id:                         null,
    externalId:                 null,
    type:                       DocumentType.WASTE_IDENTIFICATION,
    state:                      'DRAFT',
    notes:                      '',
    transportDate:              null,
    producerIdentification:     {
      language:        'NL',
      address:         null,
      shipmentAddress: null,
      identifications: [
        {
          value:              null,
          identificationType: null,
        },
      ],
    },
    organiserIdentification:    {
      language:        'NL',
      address:         null,
      identifications: [
        {
          value:              null,
          identificationType: null,
        },
      ],
    },
    transporterIdentifications: [
      {
        language:        'NL',
        address:         null,
        identifications: [
          {
            value:              null,
            identificationType: null,
          },
        ],
      },
    ],
    processorIdentification:    {
      language:        'NL',
      address:         null,
      shipmentAddress: null,
      identifications: [
        {
          value:              null,
          identificationType: null,
        },
      ],
    },
    wasteIdentifications:       [
      {
        'description':         '',
        'amount':              '',
        'euralCode':           null,
        'removalOperation':    null,
        'processingTechnique': null,
      },
    ],
    organiserSignature:         null,
    processorSignature:         null,
    startPoint:                 null,
    endPoint:                   null,
  },
  formCheck: (form, allChecks = false) => {
    let r = checkAlways(form)
    if (r) {
      return r
    }
    if (allChecks) {
      r = checkToSign(form)
      if (r) {
        return r
      }
    }
  },
  matisCheck: (form) => {
    return checkMatis(form)
  }
}

export default WasteIdentificationModel
