<template :key="template.state">
  <layout>
    <template #header>
      <ui-header :title="template.name ? template.name : $t('transport_document_templates.waste_identification.view.title', {id: template.id})" back-route="/waste-identification/list?tab=templates">
        <template #actions>
          <div class="btn-group">
            <div class="btn-group">
              <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="actionsDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                {{$t('general.actions')}}
              </button>
              <ul data-cy="organisations-actions" class="dropdown-menu" aria-labelledby="actionsDropdown">
                <li><button class="dropdown-item" @click="showManageShares()">{{ $t('transport_document_templates.view.drivers') }}</button></li>
                <li><button class="dropdown-item" @click="duplicate()">{{ $t('transport_document_templates.view.duplicate') }}</button></li>
                <li><button v-if="valid" class="dropdown-item" @click="edit()">{{ $t('general.edit') }}</button></li>
              </ul>
            </div>
            <template v-if="!valid">
              <button type="button" class="btn btn-outline-primary" @click="edit()"><i class="bi-pen" />&nbsp;{{ $t('general.edit') }}</button>
            </template>
            <template v-if="valid">
              <button v-if="template.state !== TransportDocumentTemplateState.SIGNED" type="button" class="btn btn-outline-primary" @click="showSign()"><i class="bi-check" />&nbsp;{{ $t('signature.sign') }}</button>
              <button v-if="template.state === TransportDocumentTemplateState.SIGNED && $roles([roles.USER, roles.DRIVER])" type="button" class="btn btn-outline-primary" @click="showCreateForm()"><i class="bi-plus" />&nbsp;{{ $t('transport_document_templates.view.create_form') }}</button>
            </template>
          </div>
        </template>
      </ui-header>
    </template>
    <ui-breadcrumbs :breadcrumbs="breadcrumbs" />
    <ui-content class="pb-1">
      <div class="container-fluid">
        <form id="waste-identification-templates-form" class="row g-2">
          <ui-view-card :title="$t('form.title.basic_information')" type="col-lg-12">
            <div class="row">
              <div class="col-lg-6">
                <ui-view-card-field :label="$t('form.labels.name')" :model-value="template.name" :readonly="true" />
                <ui-view-card-field :label="$t('form.labels.status')" :model-value="$t('form.state.'+template.state)" :readonly="true" />
              </div>
              <div class="col-lg-6">
                <ui-view-card-field :label="$t('form.labels.notes')">
                  <textarea class="form-control" :value="template.notes" rows="4" style="width: 100%" maxlength="256" :readonly="true" />
                </ui-view-card-field>
              </div>
            </div>
          </ui-view-card>
          <ui-view-card
            :title="$t('transport_document_templates.waste_identification.edit.producers')"
          >
            <table class="table table-bordered table-striped">
              <thead>
              <tr>
                <th></th>
                <th>{{$t('transport_document_templates.waste_identification.edit.name')}}</th>
                <th>{{$t('transport_document_templates.waste_identification.edit.address')}}</th>
                <th>{{$t('transport_document_templates.waste_identification.edit.sending_address')}}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="producer in template.producers" :key="producer.id">
                <td style="width: 1px; vertical-align: middle;">
                  <span v-if="validProducer(producer)" class="color-dot-green" />
                  <span v-else class="color-dot-red" />
                </td>
                <td class="ellipsis">{{ producer.name }}</td>
                <td>
                  <ui-view-card-address v-model="producer.address" :compact-address="true" />
                </td>
                <td>
                  <ui-view-card-address v-model="producer.shipmentAddress" :compact-address="true" />
                </td>
              </tr>
              </tbody>
            </table>
          </ui-view-card>
          <ui-view-card
            :title="$t('transport_document_templates.waste_identification.edit.processors')"
          >
            <table class="table table-bordered table-striped">
              <thead>
              <tr>
                <th></th>
                <th>{{$t('transport_document_templates.waste_identification.edit.name')}}</th>
                <th>{{$t('transport_document_templates.waste_identification.edit.address')}}</th>
                <th>{{$t('transport_document_templates.waste_identification.edit.receiving_address')}}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="processor in template.processors" :key="processor.id">
                <td style="width: 1px; vertical-align: middle;">
                  <span v-if="validProcessor(processor)" class="color-dot-green" />
                  <span v-else class="color-dot-red" />
                </td>
                <td class="ellipsis">{{processor.name}}</td>
                <td>
                  <ui-view-card-address v-model="processor.address" :compact-address="true" />
                </td>
                <td>
                  <ui-view-card-address v-model="processor.shipmentAddress" :compact-address="true" />
                </td>
              </tr>
              </tbody>
            </table>
          </ui-view-card>
          <ui-view-card
            :title="$t('transport_document_templates.waste_identification.edit.organiser')"
          >
            <table class="table table-bordered table-striped">
              <thead>
              <tr>
                <th></th>
                <th>{{$t('transport_document_templates.waste_identification.edit.name')}}</th>
                <th>{{$t('transport_document_templates.waste_identification.edit.address')}}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-if="template.organiser">
                <td style="width: 1px; vertical-align: middle;">
                  <span v-if="validOrganiser(template.organiser)" class="color-dot-green" />
                  <span v-else class="color-dot-red" />
                </td>
                <td class="ellipsis">{{template.organiser?.name}}</td>
                <td>
                  <ui-view-card-address :model-value="template.organiser.address" :compact-address="true" />
                </td>
              </tr>
              </tbody>
            </table>
          </ui-view-card>
          <ui-view-card
            :title="$t('transport_document_templates.waste_identification.edit.transporters')"
          >
            <table class="table table-bordered table-striped">
              <thead>
              <tr>
                <th></th>
                <th>{{$t('transport_document_templates.waste_identification.edit.name')}}</th>
                <th>{{$t('transport_document_templates.waste_identification.edit.address')}}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="transporter in template.transporters" :key="transporter.id">
                <td style="width: 1px; vertical-align: middle;">
                  <span v-if="validTransporter(transporter)" class="color-dot-green" />
                  <span v-else class="color-dot-red" />
                </td>
                <td class="ellipsis">{{transporter.name}}</td>
                <td>
                  <ui-view-card-address :model-value="transporter.address" :compact-address="true" />
                </td>
              </tr>
              </tbody>
            </table>
          </ui-view-card>
          <ui-view-card
            :title="$t('transport_document_templates.waste_identification.edit.waste_identification')"
          >
            <table class="table table-bordered table-striped">
              <thead>
              <tr>
                <th></th>
                <th>{{$t('waste_identification_template.form.description')}}</th>
                <th>{{$t('waste_identification_template.form.amount')}}</th>
                <th>{{$t('waste_identification_template.form.EURAL-code')}}</th>
                <th>{{$t('transport_document_templates.waste_identification.edit.processors')}}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="waste in template.wasteIdentificationTemplateProcessors" :key="waste.id">
                <td style="width: 1px; vertical-align: middle;">
                  <span v-if="validWaste(waste)" class="color-dot-green" />
                  <span v-else class="color-dot-red" />
                </td>
                <td class="ellipsis">{{ waste.wasteIdentification.description }}</td>
                <td>
                  {{ waste.wasteIdentification.amount }} {{ $t('form.table.tonne') }}
                </td>
                <td>{{ waste.wasteIdentification.euralCode ? waste.wasteIdentification.euralCode.substring(6).replaceAll('_', ' ') : '' }}
                  <template v-if="waste.dangerous">*</template>
                </td>
                <td class="ellipsis">{{ getProcessorsForWaste(waste) }}</td>
              </tr>
              </tbody>
            </table>
          </ui-view-card>
          <ui-view-card :title="$t('signature.organiser_signature')" :key="template.organiserSignature">
            <ui-view-card-signature-field :signature="template.organiserSignature" />
          </ui-view-card>
        </form>
      </div>
    </ui-content>
  </layout>
  <sign-template-modal :callback="sign" ref="signModal" />
  <template-manage-shares-modal :template="template" ref="manageSharesModal" />
  <waste-identification-templates-create-form ref="createFormModal" :template="template" :callback="createForm" />
</template>

<script>

import Layout from '@/components/layout.vue'
import UiHeader from '@/components/ui-header.vue'
import UiContent from '@/components/ui-content.vue'
import UiBreadcrumbs from '@/components/ui-breadcrumbs.vue'
import BREADCRUMBS from '@/util/breadcrumbs'
import {useRouter} from 'vue-router'
import UiViewCardAddress from '@/components/ui-view-card-address.vue'
import UiViewCardField from '@/components/ui-view-card-field.vue'
import UiViewCard from '@/components/ui-view-card.vue'
import OrganisationModel from '@/models/OrganisationModel'
import TransportDocumentTemplateModel from '@/models/TransportDocumentTemplateModel'
import {computed, ref} from 'vue'
import {DateFormatter} from '@/services/dateFormatter'
import UiViewCardSignatureField from '@/components/ui-view-card-signature-field.vue'
import Notifier from '@/util/notifier'
import SignTemplateModal from '@/views/transport-document-templates/Sign-template-modal.vue'
import TransportDocumentTemplateState from '@/types/transportDocumentTemplateState'
import backend from '@/util/backend'
import WasteIdentificationTemplatesCreateForm
  from '@/views/transport-document-templates/waste-identification/Create-form-modal.vue'
import TemplateManageSharesModal from '@/views/transport-document-templates/Manage-Shares-Modal.vue'

export default {
  name: 'WasteIdentificationTemplatesViewUser',
  computed: {
    TransportDocumentTemplateState() {
      return TransportDocumentTemplateState
    },
    DateFormatter() {
      return DateFormatter
    }
  },
  components: {
    TemplateManageSharesModal,
    WasteIdentificationTemplatesCreateForm,
    SignTemplateModal,
    UiViewCardSignatureField,
    UiViewCard,
    UiViewCardField,
    UiViewCardAddress,
    UiBreadcrumbs,
    UiContent,
    UiHeader,
    Layout
  },
  props: {
    template: {
      type:     Object,
      required: true,
    }
  },
  emits: ['reload'],
  setup: (props, {emit}) => {
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.WASTE_IDENTIFICATION_TEMPLATES, BREADCRUMBS.VIEW_TEMPLATE]

    const router = useRouter()
    const edit = () => {
      router.push(`/transport-document-templates/edit/${props.template.id}`)
    }

    const validProducer = (producer) => {
      return OrganisationModel.validProducer(producer)
    }

    const validProcessor = (processor) => {
      return OrganisationModel.validProcessor(processor)
    }

    const validOrganiser = (organiser) => {
      return OrganisationModel.validOrganiser(organiser)
    }

    const validTransporter = (transporter) => {
      return OrganisationModel.validTransporter(transporter)
    }

    const validWaste = (waste) => {
      return TransportDocumentTemplateModel.validWasteIdentificationTemplateProcessors(waste, props.template.processors)
    }

    const getProcessorsForWaste = (waste) => {
      const processorsMap = {}
      props.template.processors.forEach((proc) => {
        processorsMap[proc.id] = proc.name
      })
      let names = []
      waste?.allowedProcessors.forEach((proc) => {
        names.push(processorsMap[proc])
      })
      return names.join(', ')
    }

    const notifier = Notifier()

    const validate = (notify) => {
      const err = TransportDocumentTemplateModel.canSignWasteIdentification(props.template)
      if (notify) {
        if (err) {
          notifier.error(err)
        } else {
          notifier.success('toast.template_valid')
        }
      }
      return !err;
    }

    const valid = computed(() => {
      return validate()
    })

    const signModal = ref('signModal')
    const showSign = () => {
      if (!validate()) {
        return false
      }
      signModal.value.modal.open()
    }
    const sign = () => {
      TransportDocumentTemplateModel.setSigned(props.template).then((result) => {
        if (result.status === 200) {
          notifier.success('toast.template_signed')
        } else {
          notifier.error('toast.template_sign_failed')
        }
      }).finally(() => {
        signModal.value.modal.close()
        emit('reload')
      })
    }

    const organiserName = computed(() => {
      return props.template.organiserSignature ? props.template.organiserSignature.firstName + ' ' + props.template.organiserSignature.lastName : ''
    })

    const createFormModal = ref('createFormModal')
    const showCreateForm = () => {
      if (!validate()) {
        return false
      }
      createFormModal.value.modal.open()
    }

    const createForm = (form) => {
      backend.post(`api/transportDocumentTemplates/${props.template.id}/createDocument`, form).then((res) => {
        if (res.status === 200) {
          router.push(`/waste-identification/view/${res.data.id}`)
        } else {
          notifier.error('toast.form_create_failed')
        }
      })
      createFormModal.value.modal.close()
      createFormModal.value.init()
    }

    const manageSharesModal = ref('manageSharesModal')
    const showManageShares = () => {
      manageSharesModal.value.modal.open()
    }

    const duplicate = async () => {
      await backend.post(`api/transportDocumentTemplates/batch`, {
        ids:    [props.template.id],
        action: 'COPY'
      }).then((result) => {
        if (result.status === 200) {
          router.push(`/transport-document-templates/view/${result.data[0]}`).then(() => {
            router.go(0)
            notifier.success('toast.duplication_successful')
          })
        } else {
          notifier.error('toast.duplication_failed')
        }
      })
    }

    return {
      breadcrumbs,
      edit,
      valid,
      validate,
      showSign,
      signModal,
      sign,
      duplicate,
      showManageShares,
      manageSharesModal,
      organiserName,
      validProducer,
      validProcessor,
      validOrganiser,
      validTransporter,
      validWaste,
      getProcessorsForWaste,
      showCreateForm,
      createFormModal,
      createForm,
    }
  }
}
</script>
