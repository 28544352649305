<template>
  <ui-view-card :title="$t('transport.title')">
    <div class="row">
      <div class="card col-sm-6 p-0">
        <template v-if="start && start.coordinates">
          <ui-view-card id="transportStart" :title="$t('transport.start')" :type="'col-lg-12'">
            {{ start.firstName + ' ' + start.lastName }}
            <br />
            {{ start.userEmail }}
            <br />
            {{ start.organisation }}
            <br />
            {{ formatDate(start.timestamp) }}
            <br />
            {{$t('transport.coordinates') + ': ' + start.coordinates}}
            <template v-if="$roles([roles.SYSTEM_ADMIN])">
              <br />
              <br />
              <button type="button" class="btn btn-outline-secondary" @click="showOnMap(start.coordinates)">
                {{$t('transport.show_on_map')}}
              </button>
            </template>
          </ui-view-card>
        </template>
        <template v-else>
          <h4 class="text-center">
            {{$t('transport.transport_not_started')}}
          </h4>
        </template>
      </div>
      <div class="card col-sm-6 p-0">
        <template v-if="end && end.coordinates">
          <ui-view-card id="transportEnd" :title="$t('transport.end')" :type="'col-lg-12'">
            {{ end.firstName + ' ' + end.lastName }}
            <br />
            {{ end.userEmail }}
            <br />
            {{ end.organisation }}
            <br />
            {{ formatDate(end.timestamp) }}
            <br />
            {{$t('transport.coordinates') + ': ' + end.coordinates}}
            <template v-if="$roles([roles.SYSTEM_ADMIN])">
              <br />
              <br />
              <button type="button" class="btn btn-outline-secondary" @click="showOnMap(end.coordinates)">
                {{$t('transport.show_on_map')}}
              </button>
            </template>
          </ui-view-card>
        </template>
        <template v-else>
          <h4 class="text-center">
            {{$t('transport.transport_not_ended')}}
          </h4>
        </template>
      </div>
    </div>
  </ui-view-card>
</template>

<script>

import UiViewCard from '@/components/ui-view-card'
import geolocation from '@/services/geolocation'

export default {
  name: 'ui-view-card-transport',
  components: {UiViewCard},
  props: {
    id: {
      type:     Number,
      required: true,
    },
    start: {
      type:    Object,
      default: null,
    },
    end: {
      type:    Object,
      default: null,
    },
  },
  setup: () => {
    const opts = {
      year: 'numeric', month: 'numeric', day: 'numeric',
      hour: 'numeric', minute: 'numeric', second: 'numeric',
    }

    const formatDate = (date) => {
      const d = new Date(date)
      return new Intl.DateTimeFormat('nl', opts).format(d)
    }

    const showOnMap = (coordinates) => {
      const wgs84 = geolocation.reverse(coordinates)
      // https://www.google.com/maps/search/?api=1&query=<lat>,<lng>
      window.open(`https://www.google.com/maps/search/?api=1&query=${wgs84[1]},${wgs84[0]}`, '_blank')
    }

    return {
      formatDate,
      showOnMap,
    }
  }
}
</script>
