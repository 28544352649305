<template :key="id">
  <layout>
    <template #header>
      <ui-header :title="$t('grondbank.view.title', {id})" back-route="/grondbank/list">
        <template #actions>
          <template v-if="!loading">
            <div class="btn-group">
              <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="actionsDropdown"
                      data-bs-toggle="dropdown">
                {{ $t('general.actions') }}
              </button>
              <ul data-cy="contacts-actions" class="dropdown-menu">
                <li
                    v-if="$roles([roles.DRIVER, roles.DRIVER_BOSSCHAERT])"
                    id="fullViewBtn"
                >
                  <button v-if="showDriverView" class="dropdown-item" @click="$emit('showDriverView')">
                    {{ $t('waste_identification.view.show_simple_form') }}
                  </button>
                </li>
                <li
                  v-if="$roles([roles.USER]) && !FormState.isEndState(form.state)"
                  id="shareBtn"
                  :title="!canShare ? $t('forms.view.only_signed_shared') : ''"
                >
                  <button
                    class="dropdown-item"
                    :class="!canShare ? 'disabled' : ''"
                    @click="showShare()"
                  >
                    {{ $t('forms.view.share_with_driver') }}
                  </button>
                </li>
                <li v-if="$roles([roles.OVERVIEWER, roles.USER])" :title="!FormState.canExport(form.state) ? $t('forms.view.draft_no_export') : ''">
                  <button class="dropdown-item" :class="!FormState.canExport(form.state) ? 'disabled' : ''" @click="showExport()">
                    {{ $t('forms.view.export') }}
                  </button>
                </li>
                <li v-if="$roles([roles.USER])">
                  <button class="dropdown-item" @click="duplicate()">
                    {{ $t('forms.view.duplicate') }}
                  </button>
                </li>
                <li
                    v-if="$roles([roles.OVERVIEWER, roles.SUPPORT, roles.USER, roles.DRIVER, roles.DRIVER_BOSSCHAERT])"
                    id="changeLogBtn"
                >
                  <button class="dropdown-item" @click="showChangeLog()">
                    {{ $t('forms.view.view_change_log') }}
                  </button>
                </li>
                <li
                    v-if="$roles([roles.USER]) && !FormState.isEndState(form.state)"
                    id="deleteBtn"
                    :title="!FormState.canDelete(form.state) ? $t('forms.view.only_draft_deleted') : ''">
                  <button class="dropdown-item" :class="!FormState.canDelete(form.state) ? 'disabled' : ''"
                          @click="showDelete()">
                    {{ $t('forms.view.delete') }}
                  </button>
                </li>
                <li
                    v-if="$roles([roles.USER]) && !FormState.isEndState(form.state)"
                    id="cancelBtn"
                    :title="!FormState.canCancel(form.state) ? $t('forms.view.only_signed_cancel') : ''">
                  <button class="dropdown-item" :class="!FormState.canCancel(form.state) ? 'disabled' : ''"
                          @click="showCancel()">
                    {{ $t('forms.view.cancel') }}
                  </button>
                </li>
                <li v-if="$flag('ATTACHMENTS')">
                  <button class="dropdown-item" @click="showAttachments()">
                    {{ $t('forms.view.manage_attachments') }}
                  </button>
                </li>
              </ul>
            </div>
            <button
                v-if="$roles([roles.USER]) && form.state && !FormState.isEndState(form.state)"
                type="button" class="btn btn-outline-secondary"
                @click="edit()"><i class="bi-pen" />&nbsp;{{ $t('forms.view.edit') }}
            </button>
            <button
                v-if="canConfirm"
                type="button" class="btn btn-outline-primary"
                @click="showConfirm()"><i class="bi-check" />&nbsp;{{ $t('forms.view.confirm') }}
            </button>
            <button
                v-if="canShare"
                type="button" class="btn btn-outline-primary"
                @click="showShare()"><i class="bi-send" />&nbsp;{{ $t('forms.view.share_with_driver') }}
            </button>
          </template>
        </template>
      </ui-header>
    </template>
    <ui-breadcrumbs :breadcrumbs="breadcrumbs" />
    <ui-content class="pb-1">
      <spinner v-if="loading" />
      <template v-if="!loading">
        <grondbank-form-view :form="form" :key="form.lastUpdate" @signed="loadForm()" @ride-added="loadForm()" />
      </template>
    </ui-content>
  </layout>
  <grondbank-form v-if="!loading" style="display: none" :item="form" :key="id" />
  <form-share-modal v-if="!loading" ref="shareModal" :amount="1" :callback="share" :id="form.id" :document-type="DocumentType.GRONDBANK" @reload="loadForm" />
  <form-export-modal ref="exportModal" :amount="1" :callback="exportForm" />
  <form-duplicate-modal ref="duplicateModal" :amount="1" :callback="duplicate" />
  <form-delete-modal ref="deleteModal" :amount="1" :callback="deleteAction" />
  <form-cancel-modal ref="cancelModal" :callback="cancel" />
  <form-confirm-modal ref="confirmModal" :callback="confirm" />
  <form-finish-modal ref="finishModal" :callback="finish" />
  <form-attachments-modal v-if="!loading" ref="attachmentsModal" :id="form.id" :attachments="form.attachments" :callback="saveAttachments" />
  <form-change-log-modal ref="changeLogModal" :form="form" />
</template>

<script>

import Layout from '@/components/layout'
import UiHeader from '@/components/ui-header'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiContent from '@/components/ui-content'
import backend from '@/util/backend'
import {computed, ref, watch} from 'vue'
import Spinner from '@/components/spinner'
import {useRouter} from 'vue-router'
import {DateFormatter} from '@/services/dateFormatter'
import Notifier from '@/util/notifier'
import FormState from '@/types/formState'
import FormShareModal from '@/views/forms/shared/Share-Modal'
import FormCancelModal from '@/views/forms/shared/Cancel-Modal'
import FormFinishModal from '@/views/forms/shared/Finish-Modal'
import FormDuplicateModal from '@/views/forms/shared/Duplicate-Modal'
import FormExportModal from '@/views/forms/shared/Export-Modal'
import FormAttachmentsModal from '@/views/forms/shared/Attachments-Modal'
import OrganisationType from '@/types/organisationType'
import DocumentType from '@/types/documentType'
import GrondbankForm from '@/partials/grondbank/Form'
import GrondbankFormView from '@/partials/grondbank/FormView'
import FormDeleteModal from '@/views/forms/shared/Delete-Modal'
import FormChangeLogModal from '@/views/forms/shared/Change-Log-Modal.vue'
import GrondbankModel from '@/models/GrondbankModel'
import roles from '@/util/roles'
import FormConfirmModal from '@/views/forms/shared/Confirm-Modal.vue'

export default {
  name: 'GrondbankViewUser',
  computed: {
    DocumentType() {
      return DocumentType
    },
    OrganisationType() {
      return OrganisationType
    }
  },
  components: {
    FormConfirmModal,
    FormChangeLogModal,
    FormDeleteModal,
    GrondbankFormView,
    GrondbankForm,
    FormAttachmentsModal,
    FormExportModal,
    FormDuplicateModal,
    FormFinishModal,
    FormCancelModal,
    FormShareModal,
    Spinner,
    UiContent,
    UiBreadcrumbs,
    UiHeader,
    Layout
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    showDriverView: {
      type:    Boolean,
      default: false,
    },
  },
  emits: ['showDriverView'],
  setup: (props) => {
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.GRONDBANK, BREADCRUMBS.VIEW]
    const notifier = Notifier()
    const loading = ref(true)

    const form = ref({})
    const canShare = ref(false)
    const loadForm = () => {
      backend.get(`api/documents/${props.id}`).then((r) => {
        if (r.data.type !== DocumentType.GRONDBANK) {
          router.push(`/grondbank/list`)
        }
        form.value = r.data
        loading.value = false

        // we need the form to be rendered to perform the check, so wait a little
        setTimeout(() => {
          canConfirm.value = form.value.state && FormState.isDraft(form.value.state) && roles.hasOneOfRoles([roles.USER]) && !GrondbankModel.formCheck(form.value, FormState.isDraft(form.value.state))
          canShare.value = form.value.state && FormState.canShare(form.value.state) && roles.hasOneOfRoles([roles.USER]) && !GrondbankModel.formCheck(form.value, FormState.isDraft(form.value.state))
        }, 1)
      })
    }
    loadForm()

    watch(() => props.id, () => {
      loadForm()
    })

    const router = useRouter()
    const edit = () => {
      router.push(`/grondbank/edit/${props.id}`)
    }

    const canConfirm = ref(false)
    const confirmModal = ref('confirmModal')
    const showConfirm = () => {
      confirmModal.value.modal.open()
    }
    const confirm = () => {
      backend.put(`api/documents/${props.id}/confirm`).then((result) => {
        if (result.status !== 200) {
          notifier.error('toast.confirming_failed')
        }
      }).finally(() => {
        confirmModal.value.modal.close()
        loadForm()
      })
    }

    const exportModal = ref('exportModal')
    const showExport = () => {
      exportModal.value.modal.open()
    }
    const exportForm = (language, type) => {
      switch (type) {
        case 'PDF': {
          exportPdf(language)
          return
        }
        case 'JSON': {
          exportJson()
          return
        }
      }
    }

    const exportPdf = (language) => {
      backend.get(`api/documents/${props.id}/pdf?language=${language}`,
          {responseType: 'blob'}).then((result) => {
        if (result.status === 200) {
          const blob = new Blob([result.data], {type : 'application/pdf'})
          const url = URL.createObjectURL(blob)
          window.open(url)
        } else {
          notifier.error('toast.exporting_failed')
        }
      }).finally(() => {
        exportModal.value.modal.close()
      })
    }

    const exportJson = () => {
      backend.get(`api/documents/${props.id}/json`,
          {responseType: 'blob'}).then((result) => {
        if (result.status === 200) {
          const blob = new Blob([result.data], {type : 'application/json'})
          const url = URL.createObjectURL(blob)
          window.open(url)
        } else {
          notifier.error('toast.exporting_failed')
        }
      }).finally(() => {
        exportModal.value.modal.close()
      })
    }

    const exportTypes = [
      'PDF',
      'JSON'
    ]
    const exportType = ref('PDF')

    const date = computed(() => {
      if (!loading.value) {
        return DateFormatter.formatDate(form.value.transportDate)
      }
      return ''
    })

    const updateDate = computed(() => {
      if (!loading.value) {
        return DateFormatter.formatDateTime(form.value.lastUpdate)
      }
      return ''
    })

    const shareModal = ref('shareModal')
    const showShare = () => {
      shareModal.value.modal.open()
    }
    const share = async (shareWith) => {
      await backend.post('api/documents/batch', {
        'action': 'SHARE_DRIVER',
        'ids': [props.id],
        'params': shareWith,
      }).then((result) => {
        if (result.status === 200) {
          notifier.success('toast.sharing_successful')
        } else {
          notifier.error('toast.sharing_failed')
        }
      }).finally(() => {
        shareModal.value.modal.close()
        loadForm()
      })
    }

    const deleteModal = ref('deleteModal')
    const showDelete = () => {
      deleteModal.value.modal.open()
    }
    const deleteAction = async () => {
      await backend.delete(`api/documents/${props.id}`).then((result) => {
        if (result.status === 200) {
          notifier.success('toast.deleting_successful')
          setTimeout(() => {
            router.push(`/grondbank/list`)
          }, 2000)
        } else {
          notifier.error('toast.deleting_failed')
        }
      }).finally(() => {
        deleteModal.value.modal.close()
      })
    }

    const cancelModal = ref('cancelModal')
    const showCancel = () => {
      cancelModal.value.modal.open()
    }
    const cancel = async (reason) => {
      await backend.put(`api/documents/${props.id}/cancel`, {
        'reason': reason
      }).then((result) => {
        if (result.status === 200) {
          notifier.success('toast.cancel_successful')
        } else {
          notifier.error('toast.cancel_failed')
        }
      }).finally(() => {
        cancelModal.value.modal.close()
        loadForm()
      })
    }

    const finishModal = ref('finishModal')
    const showFinish = () => {
      finishModal.value.modal.open()
    }
    const finish = async () => {
      await backend.put(`api/documents/${props.id}/finish`).then((result) => {
        if (result.status === 200) {
          notifier.success('toast.finish_successful')
        } else {
          notifier.error('toast.finish_failed')
        }
      }).finally(() => {
        finishModal.value.modal.close()
        loadForm()
      })
    }

    const duplicateModal = ref('duplicateModal')
    const showDuplicate = () => {
      duplicateModal.value.modal.open()
    }
    const duplicate = async () => {
      await backend.post(`api/documents/${props.id}/duplicate`).then((result) => {
        if (result.status === 200) {
          backend.get(`api/documents/${props.id}`).then((doc) => {
            form.value = doc.data
            router.push(`/grondbank/view/${result.data.id}`)
          })
        } else {
          notifier.error('toast.duplication_failed')
        }
      }).finally(() => {
        duplicateModal.value.modal.close()
      })
    }

    const attachmentsModal = ref('attachmentsModal')
    const showAttachments = () => {
      attachmentsModal.value.modal.open()
    }
    const saveAttachments = async () => {
      attachmentsModal.value.modal.close()
      loadForm()
    }

    const changeLogModal = ref('changeLogModal')
    const showChangeLog = () => {
      changeLogModal.value.modal.open()
    }

    return {
      breadcrumbs,
      loading,
      date,
      updateDate,
      edit,
      exportPdf,
      shareModal,
      showShare,
      share,
      deleteModal,
      showDelete,
      deleteAction,
      cancelModal,
      showCancel,
      cancel,
      finishModal,
      showFinish,
      finish,
      loadForm,
      form,
      FormState,
      duplicateModal,
      showDuplicate,
      duplicate,
      exportModal,
      showExport,
      exportForm,
      exportTypes,
      exportType,
      attachmentsModal,
      showAttachments,
      saveAttachments,
      changeLogModal,
      showChangeLog,
      canShare,
      canConfirm,
      showConfirm,
      confirmModal,
      confirm,
    }
  }
}
</script>
