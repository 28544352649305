const ShareLinkType = {
    PROCESSOR: "PROCESSOR",
    CONTROL: "CONTROL",
    TRANSPORTER: "TRANSPORTER",
    getShareLinkType: (type) => {
        const t = type.toUpperCase()
        switch (t) {
            case ShareLinkType.PROCESSOR: {
                return ShareLinkType.PROCESSOR
            }
            case ShareLinkType.CONTROL: {
                return ShareLinkType.CONTROL
            }
            case ShareLinkType.TRANSPORTER: {
                return ShareLinkType.TRANSPORTER
            }
            default: {
                return null
            }
        }
    }
}

export default ShareLinkType
