<template>
  <div id="GrondbankFormView" class="container-fluid">
    <div class="row">
      <ui-view-card :title="$t('form.title.from', {from: form.soilOrigin.name})">
        <ui-view-card-address :model-value="form.soilOrigin.address" compact-address />
      </ui-view-card>
      <ui-view-card :title="$t('form.title.to', {to: form.soilDestination.name})">
        <ui-view-card-address :model-value="form.soilDestination.address" compact-address />
      </ui-view-card>
      <ui-view-card :title="$t('form.title.rides')" type="col-lg-6">
        <table class="table table-bordered table-striped">
          <thead>
          <tr>
            <th scope="col">{{$t('form.table.times')}}</th>
            <th scope="col">{{$t('form.table.volume')}}</th>
            <th scope="col">{{$t('form.table.signatures')}}</th>
            <th scope="col" :style="{width: '1px'}" />
          </tr>
          </thead>
          <tbody>
          <template v-for="(ride, index) in form.soilRides.slice().reverse()" :key="index">
            <tr>
              <td>
                <span v-if="ride.startOfTransport">
                  {{ ride.startOfTransport }} - {{ ride.endOfTransport }}
                </span>
              </td>
              <td>{{ ride.volume }} m³</td>
              <td>
                <div>
                  {{ $t('signature_type.ORGANISER').substring(0,1)+':'}}
                  <i class="bi-check" style="color:#4cd06d" v-if="ride.organiserSignature"></i>
                  <i class="bi-x" style="color:red" v-else></i>
                  {{ $t('signature_type.TRANSPORTER').substring(0,1)+':'}}
                  <i class="bi-check" style="color:#4cd06d" v-if="ride.transporterSignature"></i>
                  <i class="bi-x" style="color:red" v-else></i>
                  {{ $t('signature_type.PROCESSOR').substring(0,1)+':'}}
                  <i class="bi-check" style="color:#4cd06d" v-if="ride.processorSignature"></i>
                  <i class="bi-x" style="color:red" v-else></i>
                </div>
              </td>
              <td>
                <div class="btn-group">
                  <button type="button" class="btn btn-outline-secondary" @click="showEditRide(form.soilRides.length-index-1)">
                    <i class="bi-pen" />
                  </button>
                  <button type="button" class="btn btn-outline-secondary" @click="showOfferSignRide(form.soilRides.length-index-1)">
                    <i class="bi-check-square" />
                  </button>
                </div>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
      </ui-view-card>
      <ui-view-card id="GrondbankBasicInfo" data-bs-toggle="collapse" :title="$t('form.title.basic_information')" type="col-lg-6">
        <div class="row">
          <div class="col-lg-6">
            {{ $t('form.labels.unique_identifier') }}: {{'DGF-GB-'+form.id}}
          </div>
          <div class="col-lg-6">
            {{ $t('form.labels.status') }}: {{$t('form.state.'+form.state)}}
          </div>
          <div class="col-lg-6">
            {{ $t('form.labels.transport_date') }}: {{date}}
          </div>
        </div>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.organiser')" type="col-lg-4">
        <div :key="form.id + '-' + form?.organiserSignature?.signingDate">
          <ui-view-card-signature-field :signature="form.organiserSignature" :id="form.id" :signature-type="SignatureType.ORGANISER" @signed="$emit('signed')" :signable="organiserFormSignable" :sharelink="sharelink" :uuid="uuid" />
        </div>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.transporter')" type="col-lg-4">
        <div :key="form.id + '-' + form?.transporterSignature?.signingDate">
          <ui-view-card-signature-field :signature="form.transporterSignature" :id="form.id" :signature-type="SignatureType.TRANSPORTER" @signed="$emit('signed')" :signable="transporterFormSignable" :sharelink="sharelink" :uuid="uuid" />
        </div>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.processor')" type="col-lg-4">
        <div :key="form.id + '-' + form?.processorSignature?.signingDate">
          <ui-view-card-signature-field :signature="form.processorSignature" :id="form.id" :signature-type="SignatureType.PROCESSOR" @signed="$emit('signed')" :signable="processorFormSignable" :sharelink="sharelink" :uuid="uuid" />
        </div>
      </ui-view-card>
    </div>
  </div>
  <edit-ride-modal ref="editRideModal" :callback="editRide" />
  <offer-sign-ride-modal ref="offerSignRideModal" :callback="signRide" />
</template>

<script>
import UiViewCard from '@/components/ui-view-card'
import UiViewCardAddress from '@/components/ui-view-card-address'
import {computed, ref} from 'vue'
import {DateFormatter} from '@/services/dateFormatter'
import languages from '@/types/languages'
import UiViewCardSignatureField from '@/components/ui-view-card-signature-field'
import SignatureType from '@/types/signatureType'
import roles from '@/util/roles'
import GrondbankModel from '@/models/GrondbankModel'
import backend from '@/util/backend'
import Notifier from '@/util/notifier'
import FormState from '@/types/formState'
import EditRideModal from '@/views/forms/grondbank/Edit-Ride-Modal'
import OfferSignRideModal from '@/views/forms/grondbank/Offer-Sign-Ride-Modal.vue'

export default {
  name: 'GrondbankFormViewDriver',
  computed: {
    FormState() {
      return FormState
    },
    SignatureType() {
      return SignatureType
    }
  },
  components: {
    OfferSignRideModal,
    EditRideModal,
    UiViewCardSignatureField,
    UiViewCardAddress,
    UiViewCard,
  },
  props: {
    form: {
      type:     Object,
      required: true,
    },
    processorInfo: {
      type:     Object,
      default:  () => {},
    },
    uuid: {
      type:    String,
      default: '',
    },
    sharelink: {
      type:    Boolean,
      default: false,
    },
  },
  emits: [ 'signed', 'edited' ],
  setup: (props, {emit}) => {
    const notifier = Notifier()
    const date = computed(() => {
      return DateFormatter.formatDate(props.form.transportDate)
    })

    const updateDate = computed(() => {
      return DateFormatter.formatDateTime(props.form.lastUpdate)
    })

    const organiserRideSignable = (ride) => {
      return ride.startOfTransport && ride.volume && ride.volume.length > 0 && ride.description && roles.hasOneOfRoles(roles.USER) && !FormState.isEndState(props.form.state)
    }

    const transporterRideSignable = (ride) => {
      return ride.startOfTransport && ride.volume && ride.volume.length > 0 && ride.description && (roles.hasOneOfRoles(roles.DRIVER) || props.sharelink) && !FormState.isEndState(props.form.state)
    }

    const processorRideSignable = (ride) => {
      return ride.transporterSignature && (roles.hasOneOfRoles(roles.DRIVER) || props.sharelink) && !FormState.isEndState(props.form.state)
    }

    const organiserFormSignable = ref(false)
    const transporterFormSignable = ref(false)
    const processorFormSignable = ref(false)
    const checkSignable = () => {
      let allRidesFinished = true
      props.form.soilRides.forEach((ride) => {
        if (!(ride.startOfTransport && ride.volume && ride.description && ride.endOfTransport)) {
          allRidesFinished = false
        }
      })

      const err = GrondbankModel.formCheck(props.form, FormState.isDraft(props.form.state))
      organiserFormSignable.value = ((!err && roles.hasOneOfRoles(roles.USER)) || props.sharelink) && !FormState.isEndState(props.form.state)
      transporterFormSignable.value = ((!err && roles.hasOneOfRoles(roles.DRIVER)) || props.sharelink) && !FormState.isEndState(props.form.state)
      processorFormSignable.value = (((!err && roles.hasOneOfRoles(roles.DRIVER)) || props.sharelink) && props.form.organiserSignature && props.form.transporterSignature && allRidesFinished) && !FormState.isEndState(props.form.state)
    }
    setTimeout(checkSignable, 1)

    const offerSignRideIndex = ref(null)
    const offerSignRideModal = ref('offerSignRideModal')
    const showOfferSignRide = (rideIndex) => {
      offerSignRideIndex.value = rideIndex
      offerSignRideModal.value.modal.open()
    }
    const signRide = async (info) => {
      const ride = props.form.soilRides[offerSignRideIndex.value]
      const url = `api/documents/${props.form.id}/rides/${ride.id}/sign`
      backend.put(url, info).then(() => {
        notifier.success('toast.ride_signed')
        emit('signed')
      }).catch(() => {
        notifier.error('toast.ride_sign_failed')
      })
    }

    const countSignatures = (ride) => {
      return 0 + ride.organiserSignature ? 1 : 0 + ride.transporterSignature ? 1 : 0 + ride.processorSignature ? 1 : 0
    }

    const editIndex = ref(null)
    const editRideModal = ref('editRideModal')
    const showEditRide = (rideIndex) => {
      editIndex.value = rideIndex
      editRideModal.value.ride = JSON.parse(JSON.stringify(props.form.soilRides[rideIndex]))
      editRideModal.value.modal.open()
    }
    const editRide = async (ride) => {
      const form = props.form
      form.soilRides[editIndex.value] = ride
      const url = `api/documents/${form.id}`
      const result = await backend.put(url, form)
      if (result.status === 200) {
        emit('edited')
      } else {
        notifier.error('toast.save_failed')
      }
      return result
    }

    return {
      date,
      updateDate,
      languages,
      organiserRideSignable,
      transporterRideSignable,
      processorRideSignable,
      organiserFormSignable,
      transporterFormSignable,
      processorFormSignable,
      offerSignRideIndex,
      showOfferSignRide,
      offerSignRideModal,
      signRide,
      countSignatures,
      showEditRide,
      editRideModal,
      editRide,
    }
  }
}
</script>
