import ContractState from '@/types/contractState'

const ContractModel = {
    empty: {
        id: null,
        start: null,
        stop: null,
        cost: 0,
        initialCredits: 0,
        currentCredits: 0,
        contractState: ContractState.FUTURE,
        overdraft: 0,
    }
}

export default ContractModel
