<template>
  <div id="GrondbankFormView" class="container-fluid">
    <div class="row">
      <ui-view-card id="GrondbankBasicInfo" data-bs-toggle="collapse" :title="$t('form.title.basic_information')" type="col-lg-12">
        <div class="row">
          <div class="col-lg-6">
            {{ $t('form.labels.unique_identifier') }}: {{'DGF-GB-'+form.id}}
            <br />
            {{ $t('form.labels.permission_identifier') }}: {{form.permissionId}}
            <br />
            {{ $t('form.labels.grondbank_identifier') }}: {{form.soilReferenceId}}
            <br />
            <template v-if="form.externalId">
              {{ $t('form.labels.external_identifier') }}: {{form.externalId}}
              <br />
            </template>
            {{ $t('form.labels.last_update')}}: {{updateDate}}
            <br />
            {{ $t('form.labels.updated_by')}}: {{form.modifiedBy}}
          </div>
          <div class="col-lg-6">
            {{ $t('form.labels.status') }}: {{$t('form.state.'+form.state)}}
            <br />
            {{ $t('form.labels.license_plate') }}: {{form.licensePlate}}
            <br />
            {{ $t('form.labels.transport_date') }}: {{date}}
            <br />
            {{ $t('form.title.notes') }}: {{form.notes}}
          </div>
        </div>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.origin')">
        <div class="col-lg-6">
          {{ $t('form.labels.name') }}: {{form.soilOrigin.name}}
        </div>
        <ui-view-card-address :model-value="form.soilOrigin.address" />
        <div class="col-lg-6">
          {{ $t('form.labels.other_location_info') }}: {{form.soilOrigin.otherLocationDetermination}}
        </div>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.destination')">
        <div class="col-lg-6">
          {{ $t('form.labels.name') }}: {{form.soilDestination.name}}
        </div>
        <ui-view-card-address :model-value="form.soilDestination.address" />
        <div class="col-lg-6">
          {{ $t('form.labels.other_location_info') }}: {{form.soilDestination.otherLocationDetermination}}
        </div>
        <div class="col-lg-6">
          {{ $t('form.labels.destination_type') }}: {{form.soilDestination.destinationType}}
        </div>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.soil_origin_contractor')">
        <div class="col-lg-6">
          {{ $t('form.labels.name') }}: {{form.soilOriginContractor.name}}
        </div>
        <div class="col-lg-6">
          {{ $t('form.labels.responsible') }}: {{form.soilOriginContractor.responsible}}
        </div>
        <div class="col-lg-6">
          {{ $t('form.labels.phone_number') }}: {{form.soilOriginContractor.phone}}
        </div>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.transporter')">
        <div class="col-lg-6">
          {{ $t('form.labels.name') }}: {{form.soilTransporter.name}}
        </div>
        <ui-view-card-address :model-value="form.soilTransporter.address" />
        <div class="col-lg-6">
          {{ $t('form.labels.soil_bank_number') }}: {{form.soilTransporter.soilBankNumber}}
        </div>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.rides')" type="col-lg-12">
        <template #actions>
          <button v-if="!FormState.isEndState(form.state)" type="button" class="btn btn-sm btn-outline-primary" @click="showAddRide()"><i class="bi-plus" />&nbsp;{{ $t('form.table.add_ride') }}</button>
        </template>
        <table class="table table-bordered table-striped">
          <thead>
          <tr>
            <th scope="col">{{$t('form.table.ride_number')}}</th>
            <th scope="col">{{$t('form.table.start_hour')}}</th>
            <th scope="col">{{$t('form.table.volume')}}</th>
            <th scope="col">{{$t('form.table.description')}}</th>
            <th scope="col">{{$t('form.table.end_hour')}}</th>
            <th scope="col">{{$t('form.table.signature_organiser')}}</th>
            <th scope="col">{{$t('form.table.signature_transporter')}}</th>
            <th scope="col">{{$t('form.table.signature_processor')}}</th>
            <th scope="col"></th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(ride, index) in form.soilRides" :key="index">
            <tr>
              <td>{{ index + 1 }}</td>
              <td>{{ ride.startOfTransport }}</td>
              <td>{{ ride.volume }} m³</td>
              <td>{{ ride.description }}</td>
              <td>{{ ride.endOfTransport }}</td>
              <td :key="ride.id + '-' + ride?.organiserSignature?.signingDate">
                <ui-view-card-signature-field :signature="ride.organiserSignature" :id="form.id" :ride-id="ride.id" :signature-type="SignatureType.ORGANISER" @signed="$emit('signed')" :signable="organiserRideSignable(ride)" :sharelink="sharelink" :uuid="uuid" />
              </td>
              <td :key="ride.id + '-' + ride?.transporterSignature?.signingDate">
                <ui-view-card-signature-field :signature="ride.transporterSignature" :id="form.id" :ride-id="ride.id" :signature-type="SignatureType.TRANSPORTER" @signed="$emit('signed')" :signable="transporterRideSignable(ride)" :sharelink="sharelink" :uuid="uuid" />
              </td>
              <td :key="ride.id + '-' + ride?.processorSignature?.signingDate">
                <ui-view-card-signature-field v-if="ride.processorSignature" :signature="ride.processorSignature" :id="form.id" :ride-id="ride.id" :signature-type="SignatureType.PROCESSOR" @signed="$emit('signed')" :signable="processorRideSignable(ride)" :sharelink="sharelink" :uuid="uuid" />
                <h4 class="text-center" v-else-if="!processorRideSignable(ride)">
                  {{$t('signature.not_signed')}}
                </h4>
                <button v-else id="signBtn" type="button" class="btn btn-outline-primary" @click="showSignRide(index)"><i class="bi-check" />&nbsp;{{$t('signature.sign')}}</button>
              </td>
              <td>
                <div class="btn btn-outline-secondary" @click="showAddRide(ride)">
                  <i class="bi-copy" />
                </div>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.organiser')" type="col-lg-4">
        <div :key="form.id + '-' + form?.organiserSignature?.signingDate">
          <ui-view-card-signature-field :signature="form.organiserSignature" :id="form.id" :signature-type="SignatureType.ORGANISER" @signed="$emit('signed')" :signable="organiserFormSignable" :sharelink="sharelink" :uuid="uuid" />
        </div>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.transporter')" type="col-lg-4">
        <div :key="form.id + '-' + form?.transporterSignature?.signingDate">
          <ui-view-card-signature-field :signature="form.transporterSignature" :id="form.id" :signature-type="SignatureType.TRANSPORTER" @signed="$emit('signed')" :signable="transporterFormSignable" :sharelink="sharelink" :uuid="uuid" />
        </div>
      </ui-view-card>
      <ui-view-card :title="$t('form.title.processor')" type="col-lg-4">
        <div :key="form.id + '-' + form?.processorSignature?.signingDate">
          <ui-view-card-signature-field :signature="form.processorSignature" :id="form.id" :signature-type="SignatureType.PROCESSOR" @signed="$emit('signed')" :signable="processorFormSignable" :sharelink="sharelink" :uuid="uuid" />
        </div>
      </ui-view-card>
    </div>
  </div>
  <add-ride-modal ref="addRideModal" :callback="addRide" />
  <sign-ride-modal ref="signRideModal" :callback="signRide" :end-of-transport="endOfTransport" />
</template>

<script>
import UiViewCard from '@/components/ui-view-card'
import UiViewCardAddress from '@/components/ui-view-card-address'
import {computed, ref} from 'vue'
import {DateFormatter} from '@/services/dateFormatter'
import languages from '@/types/languages'
import UiViewCardSignatureField from '@/components/ui-view-card-signature-field'
import SignatureType from '@/types/signatureType'
import roles from '@/util/roles'
import GrondbankModel from '@/models/GrondbankModel'
import backend from '@/util/backend'
import Notifier from '@/util/notifier'
import FormState from '@/types/formState'
import AddRideModal from '@/views/forms/grondbank/Add-Ride-Modal'
import SignRideModal from '@/views/forms/grondbank/Sign-Ride-Modal'

export default {
  name: 'GrondbankFormView',
  computed: {
    FormState() {
      return FormState
    },
    SignatureType() {
      return SignatureType
    }
  },
  components: {
    SignRideModal,
    AddRideModal,
    UiViewCardSignatureField,
    UiViewCardAddress,
    UiViewCard,
  },
  props: {
    form: {
      type:     Object,
      required: true,
    },
    processorInfo: {
      type:     Object,
      default:  () => {},
    },
    uuid: {
      type:    String,
      default: '',
    },
    sharelink: {
      type:    Boolean,
      default: false,
    },
  },
  emits: [ 'signed', 'rideAdded' ],
  setup: (props, {emit}) => {
    const notifier = Notifier()
    const date = computed(() => {
      return DateFormatter.formatDate(props.form.transportDate)
    })

    const updateDate = computed(() => {
      return DateFormatter.formatDateTime(props.form.lastUpdate)
    })

    const organiserRideSignable = (ride) => {
      return ride.startOfTransport && ride.volume && ride.volume.length > 0 && ride.description && roles.hasOneOfRoles(roles.USER) && !FormState.isEndState(props.form.state)
    }

    const transporterRideSignable = (ride) => {
      return ride.startOfTransport && ride.volume && ride.volume.length > 0 && ride.description && (roles.hasOneOfRoles(roles.DRIVER) || props.sharelink) && !FormState.isEndState(props.form.state)
    }

    const processorRideSignable = (ride) => {
      return ride.transporterSignature && (roles.hasOneOfRoles(roles.DRIVER) || props.sharelink) && !FormState.isEndState(props.form.state)
    }

    const organiserFormSignable = ref(false)
    const transporterFormSignable = ref(false)
    const processorFormSignable = ref(false)
    const checkSignable = () => {
      let allRidesFinished = true
      props.form.soilRides.forEach((ride) => {
        if (!(ride.startOfTransport && ride.volume && ride.description && ride.endOfTransport)) {
          allRidesFinished = false
        }
      })

      const err = GrondbankModel.formCheck(props.form, FormState.isDraft(props.form.state))
      organiserFormSignable.value = ((!err && roles.hasOneOfRoles(roles.USER)) || props.sharelink) && !FormState.isEndState(props.form.state)
      transporterFormSignable.value = ((!err && roles.hasOneOfRoles(roles.DRIVER)) || props.sharelink) && !FormState.isEndState(props.form.state)
      processorFormSignable.value = (((!err && roles.hasOneOfRoles(roles.DRIVER)) || props.sharelink) && props.form.organiserSignature && props.form.transporterSignature && allRidesFinished) && !FormState.isEndState(props.form.state)
    }
    setTimeout(checkSignable, 1)

    const addRideModal = ref('addRideModal')
    const showAddRide = (ride) => {
      if (ride) {
        addRideModal.value.ride = JSON.parse(JSON.stringify(ride))
        delete addRideModal.value.ride.startOfTransport
        delete addRideModal.value.ride.endOfTransport
      } else {
        addRideModal.value.ride = JSON.parse(JSON.stringify(GrondbankModel.soilRide))
      }

      addRideModal.value.modal.open()
    }
    const addRide = async (ride) => {
      const newRide = Object.assign(JSON.parse(JSON.stringify(GrondbankModel.soilRide)), {
        description: ride.description,
        volume: ride.volume,
        startOfTransport: ride.startOfTransport.hours.toString().padStart(2, '0') + ':' + ride.startOfTransport.minutes.toString().padStart(2, '0'),
      })

      const form = props.form
      form.soilRides.push(newRide)

      const url = props.sharelink ? `api/sharedlinks/${props.uuid}/document/${props.form.id}` : `api/documents/${props.form.id}`
      const result = await backend.put(url, form)
      if (result.status === 200) {
        emit('rideAdded')
      } else {
        notifier.error('toast.save_failed')
      }
      return result
    }

    const signRideModal = ref('signRideModal')
    const showSignRide = (rideIndex) => {
      selectedRideIndex.value = rideIndex
      endOfTransport.value = props.form.soilRides[selectedRideIndex.value].endOfTransport
      signRideModal.value.modal.open()
    }
    const selectedRideIndex = ref(null)
    const endOfTransport = ref('')
    const signRide = async (info) => {
      const form = props.form
      form.soilRides[selectedRideIndex.value].endOfTransport = info.endOfTransport.hours.toString().padStart(2, '0') + ':' + info.endOfTransport.minutes.toString().padStart(2, '0')
      const rideId = form.soilRides[selectedRideIndex.value].id
      const url = props.sharelink ? `api/sharedlinks/${props.uuid}/document/${props.form.id}` : `api/documents/${props.form.id}`
      const result = await backend.put(url, props.form)
      if (result.status === 200) {
        delete info.endOfTransport
        const url = props.sharelink ? `api/sharedlinks/${props.uuid}/rides/${rideId}/sign` : `api/documents/${props.form.id}/rides/${rideId}/sign`
        backend.put(url, info).then(() => {
          notifier.success('toast.ride_signed')
          emit('signed')
        }).catch(() => {
          notifier.error('toast.ride_sign_failed')
        })
      } else {
        notifier.error('toast.ride_sign_failed')
      }
    }

    return {
      date,
      updateDate,
      languages,
      organiserRideSignable,
      transporterRideSignable,
      processorRideSignable,
      organiserFormSignable,
      transporterFormSignable,
      processorFormSignable,
      showAddRide,
      addRideModal,
      addRide,
      selectedRideIndex,
      endOfTransport,
      showSignRide,
      signRideModal,
      signRide,
    }
  }
}
</script>
