<template>
  <shared-link-driver v-if="shareLinkType === ShareLinkType.TRANSPORTER" :link="link" />
  <shared-link-processor v-if="shareLinkType === ShareLinkType.PROCESSOR" :link="link" />
  <shared-link-control v-if="shareLinkType === ShareLinkType.CONTROL" :link="link" />
</template>

<script>

import backend from '@/util/backend'
import {provide, ref} from 'vue'
import {useRouter} from 'vue-router'
import ShareLinkType from '@/types/shareLinkType'
import SharedLinkDriver from '@/views/shared-links/Shared-Link-Driver'
import SharedLinkProcessor from '@/views/shared-links/Shared-Link-Processor'
import SharedLinkControl from '@/views/shared-links/Shared-Link-Control'
import session from '@/util/session'
import sharelinkConfig from '@/services/sharelinkConfig'

export default {
  name: 'SharedLink',
  components: {
    SharedLinkControl,
    SharedLinkProcessor,
    SharedLinkDriver,
  },
  props: {
    uuid: {
      type:     String,
      required: true,
    }
  },
  setup: (props) => {
    const config = ref(sharelinkConfig)
    provide('config', config)
    const router = useRouter()
    const loading = ref(true)
    const link = ref(null)
    const shareLinkType = ref(null)
    backend.get(`api/sharedlinks/init?shareLinkUuid=${props.uuid}`).then(async () => {
      await session.setUser()
      config.value.parse(session.getOrganisation())
      backend.get(`api/sharedlinks/${props.uuid}`).then((result) => {
        shareLinkType.value = ShareLinkType.getShareLinkType(result.data.shareLinkType)
        if (shareLinkType.value == null) {
          throw new Error("Unknown shared link type!")
        }
        link.value = result.data
      })
    }).catch(() => {
      router.push({name: 'SharedExpired'})
    })

    return {
      loading,
      ShareLinkType,
      shareLinkType,
      link,
    }
  }
}
</script>
