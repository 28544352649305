<template>
  <ui-modal ref="modal" :callback="cb">
    <template #title>
      {{ $t('grondbank.start_ride.title')}}
    </template>
    <template #default>
      {{ $t('grondbank.start_ride.text') }}
      <br />
      <br />
      <form id="grondbank-start-ride-form">
        <ui-view-card-field :label="$t('form.table.start_hour')" :required="true" :readonly="true">
          <datepicker time-picker data-cy="grondbank-ride-start-time" v-model="ride.startOfTransport" readonly />
        </ui-view-card-field>
        <ui-view-card-number :label="$t('form.table.volume')" v-model="ride.volume" />
        <ui-view-card-field :label="$t('form.table.description')" v-model="ride.description" />
      </form>
    </template>
  </ui-modal>
</template>

<script>

import UiModal from '@/components/ui-modal'
import {ref} from 'vue'
import Datepicker from '@vuepic/vue-datepicker'
import UiViewCardField from '@/components/ui-view-card-field'
import UiViewCardNumber from '@/components/ui-view-card-number.vue'

export default {
  name: 'StartRideModal',
  components: {
    UiViewCardNumber,
    UiViewCardField,
    Datepicker,
    UiModal
  },
  props: {
    callback: {
      type:     Function,
      required: true,
    },
  },
  setup: (props) => {
    const modal = ref('modal')
    const ride = ref({})

    const cb = async () => {
      const formElement = $('#grondbank-start-ride-form')[0]
      if (!formElement.checkValidity()) {
        // something in the form is not valid so don't allow starting
        formElement.reportValidity()
        return false
      }

      let res = await props.callback(ride.value)
      if (res) {
        modal.value.close()
      }
    }

    return {
      modal,
      ride,
      cb,
    }
  }
}
</script>
