import {createRouter, createWebHashHistory} from 'vue-router'
import session from '@/util/session'

import Home from '../views/Home'
import Login from '../views/Login'
import Logout from '../views/Logout'
import NotFound from '../views/NotFound'
import Maintenance from '../views/Maintenance'

import OrganisationsList from '../views/organisations/List'
import OrganisationNew from '../views/organisations/New'
import OrganisationView from '../views/organisations/View'
import OrganisationEdit from '../views/organisations/Edit'

import DriversList from '../views/drivers/List'
import DriverNew from '../views/drivers/New'
import DriverView from '../views/drivers/View'
import DriverEdit from '../views/drivers/Edit'

import WasteIdentificationTemplatesList from '../views/waste-identification-templates/List'
import WasteIdentificationTemplateNew from '../views/waste-identification-templates/New'
import WasteIdentificationTemplateView from '../views/waste-identification-templates/View'
import WasteIdentificationTemplateEdit from '../views/waste-identification-templates/Edit'

import UsersList from '../views/users/List'
import UserNew from '../views/users/New'
import UserView from '../views/users/View'
import UserEdit from '../views/users/Edit'

import Profile from '../views/users/Profile'
import ProfileEdit from '../views/users/ProfileEdit'

import InvalidSignature from '../views/signatures/InvalidSignature'
import ValidSignature from '../views/signatures/ValidSignature'

import WasteIdentificationList from '../views/forms/waste-identification/List'
import WasteIdentificationView from '../views/forms/waste-identification/View'
import WasteIdentificationEdit from '../views/forms/waste-identification/Edit'

import Annex7List from '../views/forms/annex-7/List'
import Annex7View from '../views/forms/annex-7/View'
import Annex7Edit from '../views/forms/annex-7/Edit'

import GrondbankList from '../views/forms/grondbank/List'
import GrondbankView from '../views/forms/grondbank/View'
import GrondbankEdit from '../views/forms/grondbank/Edit'

import GrondwijzerList from '../views/forms/grondwijzer/List'
import GrondwijzerNew from '../views/forms/grondwijzer/New'
import GrondwijzerView from '../views/forms/grondwijzer/View'
import GrondwijzerEdit from '../views/forms/grondwijzer/Edit'

import TransportDocumentTemplatesView from '../views/transport-document-templates/View'
import TransportDocumentTemplatesEdit from '../views/transport-document-templates/Edit'

import ECmrList from '../views/forms/ecmr/List'
import ECmrNew from '../views/forms/ecmr/New'
import ECmrView from '../views/forms/ecmr/View'
import ECmrEdit from '../views/forms/ecmr/Edit'

import MatisList from '../views/matis/List'
import MatisDetail from '../views/matis/Detail'

import SharedLink from '../views/shared-links/Shared-Link'
import SharedLinkExpired from '../views/shared-links/Shared-Link-Expired'

import MyOrganisationView from '../views/my-organisation/View'
import MyOrganisationEdit from '../views/my-organisation/Edit'
import MyOrganisationLinkedOrganisations from '../views/my-organisation/Linked-Organisations'

import SupportMails from '../views/support/Mails'
import SupportMatis from '../views/support/Matis'

import SysOrgList from '../views/sys-admin/Organisation-List'
import SysOrgDetails from '../views/sys-admin/Organisation-Details'
import SysDashboard from '../views/sys-admin/Dashboard'

import ContractsNew from '../views/contracts/New'
import ContractsEdit from '../views/contracts/Edit'

import SafariCsv from "@/views/SafariCsv"
import SafariJson from "@/views/SafariJson"
import SafariPdf from "@/views/SafariPdf"

import Roles from '@/util/roles'
import backend from '@/util/backend'

// meta guest only for unauthorized users
// meta requiresAuth only for logged in users

const routes = [
    {name: 'Home', path: '/', component: Home, meta: {requiresAuth: true, roles: Roles.ALL()}},
    {name: 'Home2', path: '/home', component: Home, meta: {requiresAuth: true, roles: Roles.ALL()}},
    {name: 'Login', path: '/login', component: Login, meta: {guest: true, roles: Roles.ALL()}},
    {name: 'Logout', path: '/logout', component: Logout, meta: {guest: true, roles: [...Roles.ALL(), Roles.ANONYMOUS]}},
    {name: 'InvalidSignature', path: '/invalidSignature', component: InvalidSignature, meta: {roles: [...Roles.ALL(), Roles.ANONYMOUS]}},
    {name: 'ValidSignature', path: '/validSignature', component: ValidSignature, meta: {roles: [...Roles.ALL(), Roles.ANONYMOUS]}},
    {name: 'Maintenance', path: '/maintenance', component: Maintenance, meta: {roles: [...Roles.ALL(), Roles.ANONYMOUS]}},
    {name: 'Profile', path: '/profile', component: Profile, meta: {requiresAuth: true, roles: Roles.ALL()}},
    {name: 'ProfileEdit', path: '/profile/edit', component: ProfileEdit, meta: {requiresAuth: true, roles: Roles.ALL()}},
    {name: 'MyOrganisationView', path: '/my-organisation', component: MyOrganisationView, meta: {requiresAuth: true, roles: [Roles.ORGANISATION_ADMIN]}},
    {name: 'MyOrganisationEdit', path: '/my-organisation/edit', component: MyOrganisationEdit, meta: {requiresAuth: true, roles: [Roles.ORGANISATION_ADMIN]}},
    {name: 'MyOrganisationLinkedOrganisations', path: '/my-organisation/linked-organisations', component: MyOrganisationLinkedOrganisations, meta: {requiresAuth: true, roles: [Roles.ORGANISATION_ADMIN]}},
    {name: 'UsersList', path: '/users/list', component: UsersList, meta: {requiresAuth: true, roles: [Roles.ORGANISATION_ADMIN]}},
    {name: 'UserNew', path: '/users/new', component: UserNew, meta: {requiresAuth: true, roles: [Roles.ORGANISATION_ADMIN]}},
    {name: 'UserView', path: '/users/view/:id', component: UserView, props: true, meta: {requiresAuth: true, roles: [Roles.ORGANISATION_ADMIN]}},
    {name: 'UserEdit', path: '/users/edit/:id', component: UserEdit, props: true, meta: {requiresAuth: true, roles: [Roles.ORGANISATION_ADMIN]}},
    {name: 'OrganisationsList', path: '/organisations/list', component: OrganisationsList, meta: {requiresAuth: true, roles: [Roles.ORGANISATION_ADMIN, Roles.USER]}},
    {name: 'OrganisationNew', path: '/organisations/new', component: OrganisationNew, meta: {requiresAuth: true, roles: [Roles.ORGANISATION_ADMIN, Roles.USER]}},
    {name: 'OrganisationView', path: '/organisations/view/:id', component: OrganisationView, props: true, meta: {requiresAuth: true, roles: [Roles.ORGANISATION_ADMIN, Roles.USER]}},
    {name: 'OrganisationEdit', path: '/organisations/edit/:id', component: OrganisationEdit, props: true, meta: {requiresAuth: true, roles: [Roles.ORGANISATION_ADMIN, Roles.USER]}},
    {name: 'DriversList', path: '/drivers/list', component: DriversList, meta: {requiresAuth: true, roles: [Roles.ORGANISATION_ADMIN, Roles.USER]}},
    {name: 'DriverNew', path: '/drivers/new', component: DriverNew, meta: {requiresAuth: true, roles: [Roles.ORGANISATION_ADMIN, Roles.USER]}},
    {name: 'DriverView', path: '/drivers/view/:id', component: DriverView, props: true, meta: {requiresAuth: true, roles: [Roles.ORGANISATION_ADMIN, Roles.USER]}},
    {name: 'DriverEdit', path: '/drivers/edit/:id', component: DriverEdit, props: true, meta: {requiresAuth: true, roles: [Roles.ORGANISATION_ADMIN, Roles.USER]}},
    {name: 'WasteIdentificationTemplatesList', path: '/waste-identification-templates/list', component: WasteIdentificationTemplatesList, meta: {requiresAuth: true, roles: [Roles.ORGANISATION_ADMIN, Roles.USER]}},
    {name: 'WasteIdentificationTemplateNew', path: '/waste-identification-templates/new', component: WasteIdentificationTemplateNew, meta: {requiresAuth: true, roles: [Roles.ORGANISATION_ADMIN, Roles.USER]}},
    {name: 'WasteIdentificationTemplateView', path: '/waste-identification-templates/view/:id', component: WasteIdentificationTemplateView, props: true, meta: {requiresAuth: true, roles: [Roles.ORGANISATION_ADMIN, Roles.USER]}},
    {name: 'WasteIdentificationTemplateEdit', path: '/waste-identification-templates/edit/:id', component: WasteIdentificationTemplateEdit, props: true, meta: {requiresAuth: true, roles: [Roles.ORGANISATION_ADMIN, Roles.USER]}},
    {name: 'WasteList', path: '/waste-identification/list', component: WasteIdentificationList, meta: {requiresAuth: true, roles: Roles.ALL()}},
    {name: 'WasteView', path: '/waste-identification/view/:id', component: WasteIdentificationView, props: true, meta: {requiresAuth: true, roles: Roles.ALL()}},
    {name: 'WasteEdit', path: '/waste-identification/edit/:id', component: WasteIdentificationEdit, props: true, meta: {requiresAuth: true, roles: [Roles.ORGANISATION_ADMIN, Roles.USER, Roles.DRIVER, Roles.DRIVER_BOSSCHAERT]}},
    {name: 'Annex7List', path: '/annex-7/list', component: Annex7List, meta: {requiresAuth: true, roles: Roles.ALL()}},
    {name: 'Annex7View', path: '/annex-7/view/:id', component: Annex7View, props: true, meta: {requiresAuth: true, roles: Roles.ALL()}},
    {name: 'Annex7Edit', path: '/annex-7/edit/:id', component: Annex7Edit, props: true, meta: {requiresAuth: true, roles: [Roles.ORGANISATION_ADMIN, Roles.USER, Roles.DRIVER]}},
    {name: 'GrondbankList', path: '/grondbank/list', component: GrondbankList, meta: {requiresAuth: true, roles: Roles.ALL()}},
    {name: 'GrondbankView', path: '/grondbank/view/:id', component: GrondbankView, props: true, meta: {requiresAuth: true, roles: Roles.ALL()}},
    {name: 'GrondbankEdit', path: '/grondbank/edit/:id', component: GrondbankEdit, props: true, meta: {requiresAuth: true, roles: [Roles.ORGANISATION_ADMIN, Roles.USER, Roles.DRIVER]}},
    {name: 'GrondwijzerList', path: '/grondwijzer/list', component: GrondwijzerList, meta: {requiresAuth: true, roles: Roles.ALL()}},
    {name: 'GrondwijzerNew', path: '/grondwijzer/new', component: GrondwijzerNew, meta: {requiresAuth: true, roles: [Roles.ORGANISATION_ADMIN, Roles.USER]}},
    {name: 'GrondwijzerView', path: '/grondwijzer/view/:id', component: GrondwijzerView, props: true, meta: {requiresAuth: true, roles: Roles.ALL()}},
    {name: 'GrondwijzerEdit', path: '/grondwijzer/edit/:id', component: GrondwijzerEdit, props: true, meta: {requiresAuth: true, roles: [Roles.ORGANISATION_ADMIN, Roles.USER, Roles.DRIVER]}},
    {name: 'TransportDocumentTemplatesView', path: '/transport-document-templates/view/:id', component: TransportDocumentTemplatesView, props: true, meta: {requiresAuth: true, roles: [Roles.ORGANISATION_ADMIN, Roles.USER]}},
    {name: 'TransportDocumentTemplatesEdit', path: '/transport-document-templates/edit/:id', component: TransportDocumentTemplatesEdit, props: true, meta: {requiresAuth: true, roles: [Roles.ORGANISATION_ADMIN, Roles.USER]}},
    {name: 'MatisList', path: '/matis/list', component: MatisList, meta: {requiresAuth: true, roles: [Roles.ORGANISATION_ADMIN, Roles.USER]}},
    {name: 'MatisDetail', path: '/matis/:id', component: MatisDetail, props: true, meta: {requiresAuth: true, roles: [Roles.ORGANISATION_ADMIN, Roles.USER]}},
    {name: 'ECmrList', path: '/ecmr/list', component: ECmrList, meta: {requiresAuth: true, roles: Roles.ALL()}},
    {name: 'ECmrNew', path: '/ecmr/new', component: ECmrNew, meta: {requiresAuth: true, roles: [Roles.ORGANISATION_ADMIN, Roles.USER]}},
    {name: 'ECmrView', path: '/ecmr/view/:id', component: ECmrView, props: true, meta: {requiresAuth: true, roles: Roles.ALL()}},
    {name: 'ECmrEdit', path: '/ecmr/edit/:id', component: ECmrEdit, props: true, meta: {requiresAuth: true, roles: [Roles.ORGANISATION_ADMIN, Roles.USER, Roles.DRIVER]}},
    {name: 'SharedExpired', path: '/shared-links/expired', component: SharedLinkExpired, props: true, meta: {roles: [...Roles.ALL(), Roles.ANONYMOUS]}},
    {name: 'Shared', path: '/shared-links/:uuid', component: SharedLink, props: true, meta: {roles: [...Roles.ALL(), Roles.ANONYMOUS]}},
    {name: 'SupportMails', path: '/support/mails', component: SupportMails, props: true, meta: {requiresAuth: true, roles: [Roles.SUPPORT]}},
    {name: 'SupportMatis', path: '/support/matis', component: SupportMatis, props: true, meta: {requiresAuth: true, roles: [Roles.SUPPORT]}},
    {name: 'SysDashboard', path: '/admin/dashboard', component: SysDashboard, meta: {requiresAuth: true, roles: [Roles.SYSTEM_ADMIN]}},
    {name: 'SysOrgList', path: '/admin/organisations/list', component: SysOrgList, meta: {requiresAuth: true, roles: [Roles.SYSTEM_ADMIN]}},
    {name: 'SysOrgDetails', path: '/admin/organisations/:id', component: SysOrgDetails, props: true, meta: {requiresAuth: true, roles: [Roles.SYSTEM_ADMIN]}},
    {name: 'ContractsNew', path: '/admin/organisations/:organisationId/contracts/new', component: ContractsNew, props: true, meta: {requiresAuth: true, roles: [Roles.SYSTEM_ADMIN]}},
    {name: 'ContractsEdit', path: '/admin/organisations/:organisationId/contracts/edit/:contractId', component: ContractsEdit, props: true, meta: {requiresAuth: true, roles: [Roles.SYSTEM_ADMIN]}},

    {name: 'SafariCsv', path: '/export-csv/:ids', component: SafariCsv, props: true},
    {name: 'SafariJson', path: '/export-json/:ids', component: SafariJson, props: true},
    {name: 'SafariPdf', path: '/export-pdf/:language/:ids', component: SafariPdf, props: true},
    // will match everything and put it under `$route.params.pathMatch`
    {name: 'NotFound', path: '/:pathMatch(.*)*', component: NotFound, meta: {roles: [...Roles.ALL(), Roles.ANONYMOUS]}},
]

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes
})

router.beforeEach(async (to, from, next) => {
    if (to.path.startsWith('/validSignature') || to.path.startsWith('/invalidSignature')) {
        next()
        return
    }

    async function logout(redirect = false) {
        session.removeOrganisation()
        session.removeUser()
        session.clearSessionStorage()
        const redirectUrl = await backend.getLogout()
        if (redirect) {
            window.location = redirectUrl.data
        }
    }

    if (to.name === 'Logout') {
        if (session.getUser() || session.getOrganisation()) {
            await logout(true);
        }
        next()
        return
    }

    if (to.name === 'Login') {
        next()
        return
    }

    if (to.path.startsWith('/shared-links/')) {
        const user = session.getUser()
        const anonymous = await session.isAnonymousUser()
        if (user && !anonymous) {
            await logout()
            next()
            return
        }
        next()
        return
    }

    if (!session.getUser()) {
        await session.setUser()
    }

    if (!to.path.startsWith('/shared-links/')) {
        const anonymous = await session.isAnonymousUser()
        if (anonymous) {
            next({name: 'Logout'})
            return
        }
    }

    if (!session.getOrganisation()) {
        await session.setOrganisation()
    }

    const user = session.getUser()
    if (user) {
        // if not system admin and doesn't have one allowed role then user shouldn't be here, redirect to home
        if (!Roles.hasOneOfRoles(to.meta.roles)) {
            next({name: 'Home'})
            return
        }
    }
    next()
})

export default router
