<template>
  <layout>
    <template #header>
      <ui-header :title="loading ? '' : driver.firstName + ' ' + driver.lastName" back-route="/drivers/list">
        <template #actions>
          <button v-if="!driver.userId" type="button" class="btn btn-outline-secondary" @click="edit()"><i class="bi-pen" />&nbsp;{{ $t('general.edit') }}</button>
        </template>
      </ui-header>
    </template>
    <ui-breadcrumbs :breadcrumbs="breadcrumbs" />
    <ui-content class="pb-1">
      <spinner v-if="loading"/>
      <template v-if="!loading">
        <div class="container-fluid">
          <div class="container-fluid">
            <div class="row justify-content-center">
              <ui-view-card :title="$t('drivers.form.title')">
                <template #subtitle v-if="driver.userId">
                  <div class="mb-2">
                    {{ $t('drivers.form.generated') }}
                  </div>
                  <a
                    v-if="$roles([roles.ORGANISATION_ADMIN])"
                    class="btn btn-outline-secondary"
                    :href="`#/users/view/${driver.userId}`"
                  >
                    {{ $t('drivers.form.view_user') }}
                  </a>
                  <hr />
                </template>
                <div class="row">
                  <div class="col-lg-12">
                    {{ $t('drivers.form.email') }}: {{driver.email}}
                  </div>
                  <div class="col-lg-6">
                    {{ $t('drivers.form.first_name') }}: {{driver.firstName}}
                  </div>
                  <div class="col-lg-6">
                    {{ $t('drivers.form.last_name') }}: {{driver.lastName}}
                  </div>
                  <div class="col-lg-6">
                    {{ $t('drivers.form.organisation') }}: {{driver.organisation}}
                  </div>
                  <div class="col-lg-6">
                    {{ $t('drivers.form.language') }}: {{languages.getLanguage(driver.language)}}
                  </div>
                </div>
              </ui-view-card>
            </div>
          </div>
        </div>
      </template>
    </ui-content>
  </layout>
</template>

<script>

import Layout from '@/components/layout'
import UiHeader from '@/components/ui-header'
import UiBreadcrumbs from '@/components/ui-breadcrumbs'
import BREADCRUMBS from '@/util/breadcrumbs'
import UiContent from '@/components/ui-content'
import backend from '@/util/backend'
import {ref} from 'vue'
import Spinner from '@/components/spinner'
import UiViewCard from '@/components/ui-view-card'
import {useRouter} from 'vue-router'
import languages from '@/types/languages'

export default {
  name: 'DriverView',
  components: {
    UiViewCard,
    Spinner,
    UiContent,
    UiBreadcrumbs,
    UiHeader,
    Layout
  },
  props: {
    id: {
      type:     String,
      required: true,
    }
  },
  setup: (props) => {
    const breadcrumbs = [BREADCRUMBS.HOME, BREADCRUMBS.DRIVERS, BREADCRUMBS.VIEW]
    const router = useRouter()

    const loading = ref(true)
    const driver = ref({})
    backend.get(`api/drivers/${props.id}`).then((r) => {
      driver.value = r.data
      loading.value = false
    })

    const edit = () => {
      router.push(`/drivers/edit/${props.id}`)
    }

    return {
      breadcrumbs,
      loading,
      driver,
      edit,
      languages,
    }
  }
}
</script>
